import React, { useState } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import UpdateTreatmentStatus from "../../../UpdateTreatmentStatus";
import * as Vaccine from "../../../../controller/data/vaccine.js";
import * as Paginate from "../../../../helpers/paginate.js";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Chip,
  Typography,
  Tooltip,
} from "@material-ui/core";

class UpcomingVaccination extends React.Component {
  state = {
    vaccines: [],
    addVaccineButtonDisabled: true,
    isLoaded: false,
  };

  componentDidMount() {
    Vaccine.getUpcomingVaccines().then((response) => {
      if (response) {
        response.json().then((data) => {
          this.setState({
            vaccines: data.treatments,
            addVaccineButtonDisabled: data.addTreatmentDisabled,
            isLoaded: true,
          });
        });
      }
    });
  }

  render() {
    return (
      <ListVaccines
        vaccines={this.state.vaccines}
        addVaccineButtonDisabled={this.state.addVaccineButtonDisabled}
        isLoaded={this.state.isLoaded}
      />
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  alertMessage: {
    padding: 20,
    color: theme.palette.alert,
    textAlign: "center",
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  statusRoot: {
    marginRight: theme.spacing(1),
  },
  statusLabel: {
    color: "#FFFF",
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const statusColors = {
  valid: "#17994d",
  expiring: "#ff9100",
  expired: "#ff3d00",
};

const statusLabels = {
  valid: "Em dia",
  expiring: "Perto de expirar",
  expired: "Expirada",
};

const ListVaccinesCardContent = (props) => {
  const { vaccines, isLoaded } = props;
  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const [limit] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  if ((vaccines === undefined || vaccines.length === 0) && isLoaded) {
    return (
      <CardContent className={classes.content}>
        <Typography className={classes.alertMessage} variant="h5">
          {t('Nenhuma vacina agendada para os próximos dois meses.')}
        </Typography>
      </CardContent>
    );
  } else {
    return (
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Vacina</TableCell>
                  <TableCell width="20%">Pet</TableCell>
                  <TableCell width="10%">Próxima Dose</TableCell>
                  <TableCell width="17%">Situação</TableCell>
                  <TableCell width="10%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Paginate.paginateData(vaccines, page, limit).map((vaccine) => (
                  <TableRow
                    data-testid={"vaccine-" + vaccine.treatment.id}
                    key={vaccine.treatment.id}
                  >
                    <TableCell width="17%">{vaccine.treatment.name}</TableCell>
                    <TableCell width="17%">{vaccine.pet.name}</TableCell>
                    <TableCell width="17%">
                      {vaccine.treatment.dates.nextDate}
                    </TableCell>
                    <TableCell width="17%">
                      <div className={classes.statusContainer}>
                        <Chip
                          classes={{
                            root: classes.statusRoot,
                            label: classes.statusLabel,
                          }}
                          style={{
                            backgroundColor:
                              statusColors[vaccine.treatment.state],
                          }}
                          label={statusLabels[vaccine.treatment.state]}
                          size="medium"
                        />
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <UpdateTreatmentStatus
                        pet={vaccine.pet}
                        treatment={vaccine.treatment}
                        treatmentType="da Vacina"
                        component="chip"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={vaccines.length}
          onChangePage={handlePageChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10]}
          labelDisplayedRows={(from = page) =>
            `${from.from}-${from.to === -1 ? from.count : from.to} de ${from.count
            }`
          }
        />
      </CardContent>
    );
  }
};

const ListVaccines = (props) => {
  const { t, i18n } = useTranslation();
  const {
    className,
    vaccines,
    addVaccineButtonDisabled,
    isLoaded,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        /* action={
          <Tooltip title="Adicionar nova vacinação">
            <IconButton
              color="primary"
              size="medium"
              component={Link}
              to={{
                pathname: "/add-vaccine",
                origin: "dashboard",
              }}
              disabled={addVaccineButtonDisabled}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        } */
        title={t('Próximas Vacinas')}
        subheader={t('Vacinas que irão expirar nos próximos 2 meses')}
      />
      <Divider />
      <ListVaccinesCardContent vaccines={vaccines} isLoaded={isLoaded} />
    </Card>
  );
};

ListVaccines.propTypes = {
  className: PropTypes.string,
};

UpcomingVaccination.propTypes = {
  className: PropTypes.string,
};

export default UpcomingVaccination;
