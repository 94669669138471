import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Grid,
  Typography,
  Divider,
  Dialog,
  Button,
  DialogTitle,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    minWidth: 450,
    minHeight: 150,
  },
  item: {
    margin: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(4),
    textAlign: "center",
  },
}));

function DeleteDialog(props) {
  const { onClose, open, onConfirm } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        className={classes.title}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Tem certeza de que deseja apagar sua conta da base de dados? <br />
        Você terá 10 dias para desfazer esta ação ao fazer login novamente.
      </DialogTitle>
      <Divider />
      <Grid container justify="center">
        <Button
          className={classes.item}
          color="primary"
          variant="contained"
          type="submit"
          onClick={() => handleClose()}
        >
          Cancelar
        </Button>
        <Button
          className={classes.item}
          color="primary"
          variant="contained"
          type="submit"
          onClick={() => handleConfirmClick()}
        >
          Confirmar
        </Button>
      </Grid>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const DeleteAccountView = (props) => {
  const [open, setOpen] = React.useState(false);
  const { deleteAccount } = props;

  const handleDelete = () => {
    deleteAccount();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        data-testid="delete-account"
        variant="text"
        onClick={handleClickOpen}
        size="small"
      >
        <Typography variant="body2" color="primary">
          Apagar minha conta &nbsp;
        </Typography>
        <DeleteIcon
          color="primary"
          fontSize="small"
          titleAccess="Apagar minha conta"
        />
      </Button>

      <DeleteDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleDelete}
      />
    </div>
  );
};

class DeleteAccount extends React.Component {
  render() {
    return <DeleteAccountView deleteAccount={this.props.deleteAccount} />;
  }
}

export default DeleteAccount;
