import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/core/styles";
import * as Account from "../../controller/data/account.js";
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  Hidden,
  Tooltip,
} from "@material-ui/core";
import { LoadingDisco, PasswordInput } from "components";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    position: "static",
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    width: "100%",
    alignItems: "center",
    paddingLeft: "25%",
    paddingRight: "25%",
    paddingTop: "20%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    textAlign: "center",
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  errorText: {
    color: theme.palette.errors,
  },
  errorMessage: {
    paddingTop: theme.spacing(1),
  },
}));

const SignIn = (props) => {
  const { history } = props;
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    showError: false,
    loading: false,
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    setFormState((formState) => ({
      ...formState,
      loading: true,
    }));
    Account.signIn(formState.values.email, formState.values.password).then(
      (response) => {
        if (response.ok) {
          localStorage.removeItem("showEmailSnackbar");
          response.json().then((user) => {
            if (!user.email.confirmed) {
              localStorage.setItem("showEmailSnackbar", true);
            }
          });
          window.setTimeout(() => {
            let intended = history.location.state;
            if (intended) history.push(intended.from);
            else history.push("/dashboard");
          }, 2000);
        } else {
          setFormState((formState) => ({
            ...formState,
            showError: true,
            loading: false,
          }));
        }
      }
    );
  };

  return (
    <div className={classes.root}>
      {formState.loading && <LoadingDisco />}
      <div className={classes.content}>
        <div className={classes.contentBody}>
          <form className={classes.form} onSubmit={handleSignIn}>
            <Hidden lgUp>
              <Tooltip title="Olá! Meu nome é Matilda. " aria-label="add">
                <img
                  alt="Matilda"
                  className={classes.img}
                  src="/images/matilda_home_small.png"
                  width="15%"
                  height="15%"
                />
              </Tooltip>
              <br />
              <img
                className={classes.logo}
                alt="Logo"
                src="/images/VacinaPet_Gray.png"
              />
            </Hidden>
            <TextField
              className={classes.textField}
              fullWidth
              label="Email"
              name="email"
              onChange={handleChange}
              type="text"
              value={formState.values.email || ""}
              variant="outlined"
            />
            <PasswordInput
              label="Senha"
              name="password"
              value={formState.values.password}
              handleChange={handleChange}
              labelWidth={37}
            />
            {formState.showError && (
              <Typography
                className={classes.errorMessage}
                color="error"
                variant="body1"
              >
                Email e/ou senha inválido(s)
              </Typography>
            )}
            <Button
              className={classes.signInButton}
              color="primary"
              disabled={!formState.isValid}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Entrar
            </Button>
            <Grid container>
              <Grid item lg={6} xs={12}>
                <Typography color="textSecondary" variant="body1">
                  É novo por aqui?{" "}
                  <Link component={RouterLink} to="/sign-up" variant="h6">
                    Criar nova conta
                  </Link>
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography color="textSecondary" variant="body1">
                  <Link
                    component={RouterLink}
                    to="/forgot-password"
                    variant="h6"
                  >
                    Esqueceu a senha?
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignIn);
