import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Snackbar, Link } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { MyPets, UpcomingVaccination, UpcomingMedicine } from "./components";
import * as Account from "../../controller/data/account.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  element: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const Dashboard = (props) => {
  const { history, ...rest } = props;
  const classes = useStyles();

  const [openSnackBar, setOpenSnackBar] = React.useState(
    localStorage.getItem("showEmailSnackbar") === "true"
  );
  const [showMsg, setShowMsg] = React.useState({
    show: false,
    msg: "",
    severity: "",
  });

  const handleSnackBarClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleMsgClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowMsg((showMsg) => ({
      ...showMsg,
      show: false,
    }));
  };

  const resendConfirmationEmail = () => {
    setOpenSnackBar(false);
    Account.requestEmailConfirmation().then((response) => {
      if (response.ok) {
        setShowMsg({
          show: true,
          msg:
            "E-mail enviado! Verifique sua caixa de e-mail. Se necessário, verifique sua caixa de spam.",
          severity: "success",
        });
      } else {
        setShowMsg({
          show: true,
          msg: "Falha ao enviar e-mail",
          severity: "error",
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <div className={classes.element}>
            <MyPets history={history} />
          </div>
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <div className={classes.element}>
              <UpcomingVaccination />
            </div>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <div className={classes.element}>
              <UpcomingMedicine />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={openSnackBar} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity="info">
          A confirmação do seu e-mail está pendente e é necessaria para o envio
          de lembretes e informações importantes. Confirme-o através do link
          enviado no momento de seu cadastro ou{" "}
          <Link href="#" onClick={resendConfirmationEmail}>
            solicite novo e-mail de confirmação
          </Link>{" "}
          .
        </Alert>
      </Snackbar>
      <Snackbar
        open={showMsg.show}
        autoHideDuration={4000}
        onClose={handleMsgClose}
      >
        <Alert onClose={handleMsgClose} severity={showMsg.severity}>
          {showMsg.msg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Dashboard;
