import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Footer } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 64,
    height: "100%",
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    height: "100%",
    width: "100%",
  },
  footer: {
    alignSelf: "flex-end",
  },
}));

const Home = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.contentChildren}>
        <Grid className={classes.content} item>
          <main>{children}</main>
        </Grid>
        <Grid item className={classes.footer}></Grid>
        <Footer />
      </Grid>
    </div>
  );
};

Home.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Home;
