import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
  },
}));

const PasswordInput = (props) => {
  const {
    label,
    name,
    value,
    labelWidth,
    handleChange,
    error,
    helperText,
    ...rest
  } = props;

  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <FormControl
        className={classes.textField}
        variant="outlined"
        fullWidth
        error={error}
      >
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          {...rest}
          type={showPassword ? "text" : "password"}
          value={value}
          name={name}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={labelWidth}
        />
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  labelWidth: PropTypes.number,
  handleChange: PropTypes.func,
  showHelper: PropTypes.bool,
  helperText: PropTypes.string,
};

export default PasswordInput;
