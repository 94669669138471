import React, { useState, useEffect } from "react";
import validate from "validate.js";
import PropTypes from "prop-types";
import * as Vaccine from "../../../controller/data/vaccine.js";
import * as Pet from "../../../controller/data/pet.js";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  Breadcrumbs,
} from "@material-ui/core";
import { dateFormat, Today } from "helpers";
import format from "date-fns/format";
import DateFnsUtils from "@date-io/date-fns";
import ptBRLocale from "date-fns/locale/pt-BR";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const schema = {
  id: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 600,
    },
  },
  date: {
    presence: { allowEmpty: false, message: "is required" },
  },
  next: {
    presence: { allowEmpty: false, message: "is required" },
  },
  vaccineName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  veterinary: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
  },
  element: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  errorMessage: { textAlign: "right", paddingRight: theme.spacing(2) },
}));

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "dd MM yyyy", { locale: this.locale });
  }
}

class AddVaccine extends React.Component {
  state = {
    pets: [],
    loaded: false,
  };

  componentDidMount() {
    if (
      this.props.location.state != undefined &&
      this.props.location.state.pet != undefined
    ) {
      this.setState({
        pets: { pets: [this.props.location.state.pet] },
        loaded: true,
      });
    } else {
      Pet.getAllPets().then((response) => {
        if (response) {
          response.json().then((json) => {
            this.setState({
              pets: json,
              loaded: true,
            });
          });
        } else {
          this.setState({
            pets: { pets: [] },
            loaded: true,
          });
        }
      });
    }
  }

  render() {
    if (this.state.loaded) {
      return (
        <AddVaccineForm
          pets={this.state.pets}
          history={this.props.history}
          origin={this.props.location.origin}
        />
      );
    }
    return null;
  }
}

const AddVaccineForm = (props) => {
  const { history, origin, pets } = props;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      date: Today.date,
      dateValue: Today.string(),
      next: Today.date,
      nextValue: Today.string(),
    },
    touched: {},
    errors: {},
    showError: false,
  });

  const [changedState, setChangedState] = useState({
    id: true,
    vaccineName: false,
    vaccineAdditionalInfo: false,
    veterinary: false,
    date: true,
    next: true,
  });

  // The problem of not correcting setting the id while
  // rendering only happens in development mode because of React.StrictMode
  // This block fixes the issue when the id is not set correctly.
  // Fix this later based on https://medium.com/@andreasheissenberger/react-components-render-twice-any-way-to-fix-this-91cf23961625#:~:text=The%20reason%20why%20this%20happens,effects%20in%20the%20render%20phase.
  if (formState.values.id === undefined && pets.pets.length > 0) {
    formState.values.id = pets.pets[0].id;
  }

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleDateChange = (date, value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        date: date,
        dateValue: value,
      },
    }));
    setChangedState((changedState) => ({
      ...changedState,
      date: true,
    }));
  };

  const handleNextChange = (date, value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        next: date,
        nextValue: value,
      },
    }));
    setChangedState((changedState) => ({
      ...changedState,
      next: true,
    }));
  };

  const handleAddVaccine = (event) => {
    event.preventDefault();

    Vaccine.addVaccine(
      formState.values.id,
      formState.values.vaccineName,
      formState.values.vaccineAdditionalInfo,
      formState.values.veterinary,
      formState.values.dateValue,
      formState.values.nextValue
    ).then((response) => {
      if (response.ok) {
        history.goBack();
      } else {
        setFormState((formState) => ({
          ...formState,
          showError: true,
        }));
        if (response) {
          response.text().then((msg) => {
            setFormState((formState) => ({
              ...formState,
              errorMessage: msg,
            }));
          });
        }
      }
    });
  };

  const handleCancel = (event) => {
    event.preventDefault();
    history.goBack();
  };

  const hasError = (field) => {
    const error = formState.errors[field] ? true : false;
    return error && changedState[field];
  };

  useEffect(() => {
    validate.extend(validate.validators.datetime, dateFormat);
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <div style={{ display: "flex" }} className={classes.element}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                color="primary"
                variant="h6"
                component={Link}
                to={{
                  pathname: "/dashboard",
                }}
              >
                Dashboard
              </Typography>

              {origin === "pet-profile" ? (
                <Typography
                  component={Link}
                  variant="h6"
                  color="primary"
                  to={{
                    pathname: "/pet-profile",
                    state: {
                      pet: pets.pets[0],
                    },
                  }}
                >
                  {pets.pets[0].name}
                </Typography>
              ) : null}

              <Typography variant="h6" color="textPrimary">
                Adicionar Vacina
              </Typography>
            </Breadcrumbs>
          </div>
        </Grid>
      </Grid>
      <form onSubmit={handleAddVaccine} noValidate className={classes.element}>
        <Card>
          <CardHeader title="Adicionar Vacina" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  inputProps={{ "data-testid": "id" }}
                  fullWidth
                  label="Selecione o pet"
                  onChange={handleChange}
                  name="id"
                  required
                  select
                  SelectProps={{ native: true }}
                  value={formState.values.id}
                  variant="outlined"
                >
                  {pets.pets.map((pet) => (
                    <option key={pet.id} value={pet.id}>
                      {pet.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  inputProps={{ "data-testid": "vaccineName" }}
                  fullWidth
                  label="Nome da Vacina"
                  placeholder="Nome da Vacina"
                  name="vaccineName"
                  required
                  value={formState.values.vaccineName || ""}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  inputProps={{ "data-testid": "vaccineAdditionalInfo" }}
                  fullWidth
                  label="Informações Adicionais"
                  placeholder="Informações Adicionais"
                  name="vaccineAdditionalInfo"
                  value={formState.values.vaccineAdditionalInfo || ""}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  inputProps={{ "data-testid": "veterinary" }}
                  fullWidth
                  label="Nome da(o) Veterinária(o) e/ou Clínica"
                  placeholder="Nome da(o) Veterinária(o) e/ou Clínica"
                  name="veterinary"
                  required
                  value={formState.values.veterinary || ""}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <MuiPickersUtilsProvider
                  utils={LocalizedUtils}
                  locale={ptBRLocale}
                >
                  <KeyboardDatePicker
                    inputProps={{ "data-testid": "date" }}
                    fullWidth
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={formState.values.date}
                    label="Data da Última Vacina"
                    placeholder="Data da Última Vacina"
                    helperText={
                      hasError("date")
                        ? "Por favor, escolha a data da última dose desta vacina para este pet"
                        : ""
                    }
                    error={hasError("date")}
                    onChange={handleDateChange}
                    name="date"
                    openTo="year"
                    views={["year", "month", "date"]}
                    required
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <MuiPickersUtilsProvider
                  utils={LocalizedUtils}
                  locale={ptBRLocale}
                >
                  <KeyboardDatePicker
                    inputProps={{ "data-testid": "next" }}
                    fullWidth
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={formState.values.next}
                    label="Data da Próxima Dose da Vacina"
                    placeholder="Data da Próxima Dose da Vacina"
                    helperText={
                      hasError("next")
                        ? "Por favor, escolha a data da próxima dose desta vacina para este pet"
                        : ""
                    }
                    error={hasError("next")}
                    onChange={handleNextChange}
                    name="date"
                    openTo="year"
                    views={["year", "month", "date"]}
                    required
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          {formState.showError && (
            <Typography
              className={classes.errorMessage}
              color="error"
              variant="body1"
            >
              {formState.errorMessage}
            </Typography>
          )}
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              data-testid="cancel"
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={handleCancel}
            >
              Cancelar
            </Button>

            <Button
              data-testid="submit"
              color="primary"
              variant="contained"
              disabled={!formState.isValid}
              className={classes.button}
              type="submit"
            >
              Salvar
            </Button>
          </Box>
        </Card>
      </form>
    </div>
  );
};

AddVaccine.propTypes = {
  origin: PropTypes.string,
  history: PropTypes.object,
};

export default AddVaccine;
