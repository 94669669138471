import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, Redirect } from "react-router-dom";
import * as Paginate from "../../../helpers/paginate.js";
import * as Vaccine from "../../../controller/data/vaccine.js";
import DeleteTreatmentComponent from "../../PetDashboard/DeleteTreatmentComponent";
import UpdateTreatmentStatus from "../../UpdateTreatmentStatus";
import {
  CardHeader,
  Card,
  CardContent,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
  Breadcrumbs,
  CardActions,
  Button,
} from "@material-ui/core";
import { FormatListBulletedRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  element: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  content: {
    minHeight: 300,
  },
  info: {
    padding: 10,
    width: 300,
  },
}));

const statusLabels = {
  valid: "Em dia",
  expiring: "Perto de expirar",
  expired: "Expirada",
};

class VaccineInformation extends React.Component {
  state = {
    treatment: {},
    loaded: false,
  };

  componentDidMount() {
    if (
      this.props.location.state != undefined &&
      this.props.location.state.pet != undefined &&
      this.props.location.state.treatment != undefined
    ) {
      var pet = this.props.location.state.pet;
      var treatment = this.props.location.state.treatment;
      Vaccine.getOnePetVaccine(pet.id, treatment.id).then((response) => {
        if (response) {
          response.json().then((data) => {
            this.setState({
              treatment: data,
              loaded: true,
            });
          });
        }
      });
    } else {
      this.props.history.push("/dashboard");
    }
  }
  render() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state.pet === undefined ||
      this.props.location.state.treatment === undefined
    ) {
      return <Redirect to="/dashboard" />;
    } else if (this.state.loaded) {
      return (
        <VaccineInformationComponent
          pet={this.props.location.state.pet}
          treatment={this.state.treatment}
          history={this.props.history}
        />
      );
    }
    return null;
  }
}

const VaccineInformationComponent = (props) => {
  const { history, pet, treatment } = props;
  const classes = useStyles();

  const [limit] = useState(5);
  const [page, setPage] = useState(0);
  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleInfoClick = () => {
    history.push({
      pathname: "/edit-vaccine",
      origin: "pet-profile",
      state: {
        pet: pet,
        vaccine: treatment,
      },
    });
  };

  const deleteVaccine = () => {
    Vaccine.deleteVaccine(pet.id, treatment.id).then((response) => {
      if (response.ok) {
        history.push({
          pathname: "/pet-profile",
          state: {
            pet: pet,
          },
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <div style={{ display: "flex" }} className={classes.element}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                color="primary"
                variant="h6"
                component={Link}
                to={{
                  pathname: "/dashboard",
                }}
              >
                Dashboard
              </Typography>

              <Typography
                component={Link}
                variant="h6"
                color="primary"
                to={{
                  pathname: "/pet-profile",
                  state: {
                    pet: pet,
                  },
                }}
              >
                {pet.name}
              </Typography>

              <Typography variant="h6" color="textPrimary">
                {treatment.name}
              </Typography>
            </Breadcrumbs>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={6} md={12} xl={6} xs={12}>
          <div className={classes.element}>
            <Card className={classes.content}>
              <Divider />
              <CardHeader
                action={
                  <DeleteTreatmentComponent
                    petId={pet.id}
                    treatment={treatment}
                    deleteTreatment={deleteVaccine}
                  />
                }
                title={"Detalhes da Vacina"}
              />
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xl={6}
                    xs={12}
                    className={classes.info}
                  >
                    <Typography color="textPrimary" variant="h5">
                      Nome da Vacina:
                    </Typography>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body1"
                    >
                      {treatment.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xl={6}
                    xs={12}
                    className={classes.info}
                  >
                    <Typography color="textPrimary" variant="h5">
                      Veterinária(o) ou Clínica:
                    </Typography>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body1"
                    >
                      {treatment.veterinary}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xl={6}
                    xs={12}
                    className={classes.info}
                  >
                    <Typography color="textPrimary" variant="h5">
                      Data da Próxima Dose:
                    </Typography>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body1"
                    >
                      {treatment.dates.nextDate}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xl={6}
                    xs={12}
                    className={classes.info}
                  >
                    <Typography color="textPrimary" variant="h5">
                      Situação:
                    </Typography>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body1"
                    >
                      {statusLabels[treatment.state]}
                    </Typography>
                  </Grid>
                  {treatment.additionalInfo != undefined && (
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.info}
                    >
                      <Typography color="textPrimary" variant="h5">
                        Informações Adicionais:
                      </Typography>
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="body1"
                      >
                        {treatment.additionalInfo}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <Divider />
              <CardActions onClick={handleInfoClick}>
                <Button color="primary" fullWidth variant="text">
                  Editar Informações
                </Button>
              </CardActions>
            </Card>
          </div>
        </Grid>
        <Grid item lg={6} md={12} xl={6} xs={12}>
          <div className={classes.element}>
            <Card className={classes.content}>
              <Divider />
              <CardHeader
                action={
                  <UpdateTreatmentStatus
                    pet={pet}
                    treatment={treatment}
                    treatmentType="da Vacina"
                  />
                }
                title="Datas de Aplicação"
              />
              <Divider />
              <CardContent>
                <PerfectScrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="50%">Aplicada em</TableCell>
                        <TableCell width="50%">Válida até</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Paginate.paginateData(
                        treatment.dates.oldDates,
                        page,
                        limit
                      ).map((date) => (
                        <TableRow>
                          <TableCell width="50%">{date.date}</TableCell>
                          <TableCell width="50%">{date.nextDate}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </PerfectScrollbar>
                <TablePagination
                  component="div"
                  count={treatment.dates.oldDates.length}
                  onChangePage={handlePageChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[10]}
                  labelDisplayedRows={(from = page) =>
                    `${from.from}-${from.to === -1 ? from.count : from.to} de ${
                      from.count
                    }`
                  }
                />
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

VaccineInformation.propTypes = {
  history: PropTypes.object,
};

export default VaccineInformation;
