import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  circularLoadDiv: {
    backgroundColor: theme.palette.white,
    zIndex: 1200,
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "0",
    top: "0",
    opacity: 0.5,
  },
  circularLoad: {
    marginLeft: "50%",
    marginTop: "25%",
  },
}));

const LoadingDisco = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.circularLoadDiv}>
      <CircularProgress className={classes.circularLoad} />
    </div>
  );
};

export default LoadingDisco;
