import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import InputIcon from "@material-ui/icons/Input";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  logoText: {
    color: theme.palette.white,
  },
  logo: {
    width: theme.spacing(15),
    height: theme.spacing(4),
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>

        <RouterLink to="/">
          <img
            className={classes.logo}
            alt="Logo"
            src="/images/VacinaPet.png"
          />
        </RouterLink>

        <div className={classes.flexGrow} />

        <Hidden mdDown>
          <RouterLink className={classes.logoText} to="/settings">
            <IconButton color="inherit">
              <SettingsIcon />
            </IconButton>
          </RouterLink>

          <RouterLink className={classes.logoText} to="/sign-out">
            <IconButton className={classes.signOutButton} color="inherit">
              <Typography className={classes.logoText} variant="h5">
                Sair &nbsp;
              </Typography>
              <InputIcon />
            </IconButton>
          </RouterLink>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
