import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  CardHeader,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import DeletePetComponent from "./DeletePetComponent";
import * as Pet from "../../controller/data/pet.js";

const useStyles = makeStyles((theme) => ({
  root: {},
  info: {
    padding: 10,
  },
  deleteButton: { paddingRight: theme.spacing(1) },
  deleteButtonIcon: { color: "#FFFFFF" },
}));

const PetInformation = ({ pet, className, history, ...rest }) => {
  const classes = useStyles();

  const handleDeletePet = () => {
    Pet.deletePet(pet.id).then((response) => {
      if (response.ok) {
        history.push("/dashboard");
      }
    });
  };

  const handleInfoClick = () => {
    history.push({
      pathname: "/edit-pet",
      state: {
        pet: pet,
      },
    });
  };

  if (pet === undefined) {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          action={<DeletePetComponent deletePet={handleDeletePet} />}
          title="Detalhes do Pet"
        />
        <Divider />
        <CardContent>
          <Grid direction={"row"} container>
            <Grid item lg={6} md={6} xl={6} xs={12} className={classes.info}>
              <Typography color="textPrimary" variant="h5">
                Nome:
              </Typography>
              <Typography color="textPrimary" gutterBottom variant="body1">
                {pet.name}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={12} className={classes.info}>
              <Typography color="textPrimary" variant="h5">
                Espécie:
              </Typography>
              <Typography color="textPrimary" gutterBottom variant="body1">
                {pet.species}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={12} className={classes.info}>
              <Typography color="textPrimary" variant="h5">
                Sexo:
              </Typography>
              <Typography color="textPrimary" gutterBottom variant="body1">
                {pet.sex === "female" ? "Fêmea" : "Macho"}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={12} className={classes.info}>
              <Typography color="textPrimary" variant="h5">
                Data de Nascimento:
              </Typography>
              <Typography color="textPrimary" gutterBottom variant="body1">
                {pet.birthdate}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions onClick={handleInfoClick}>
          <Button color="primary" fullWidth variant="text">
            Editar Informações
          </Button>
        </CardActions>
      </Card>
    );
  }
};

PetInformation.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

export default PetInformation;
