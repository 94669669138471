import React from "react";
import { Switch, Redirect } from "react-router-dom";

import {
  RouteWithLayout,
  ProtectedRouteWithLayout,
  SignInRouteWithLayout,
  SignOutRouteWithLayout,
  HomeRouteWithLayout,
} from "./components";
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Single as SingleLayout,
  Home as HomeLayout,
} from "./layouts";

import {
  Dashboard as DashboardView,
  Settings as SettingsView,
  SignUp as SignUpView,
  NotFound as NotFoundView,
  AddVaccine as AddVaccineView,
  AddMedicine as AddMedicineView,
  AddPet as AddPetView,
  EditPet as EditPetView,
  PetDashboard as PetDashboardView,
  ConfirmEmail as ConfirmEmailView,
  EditVaccine as EditVaccineView,
  EditMedicine as EditMedicineView,
  ForgotPassword as ForgotPasswordView,
  ResetPassword as ResetPasswordView,
  VaccineInformation as VaccineInformationView,
  MedicineInformation as MedicineInformationView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <ProtectedRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <ProtectedRouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <ProtectedRouteWithLayout
        component={AddVaccineView}
        exact
        layout={MainLayout}
        path="/add-vaccine"
      />
      <ProtectedRouteWithLayout
        component={AddMedicineView}
        exact
        layout={MainLayout}
        path="/add-medicine"
      />
      <ProtectedRouteWithLayout
        component={AddPetView}
        exact
        layout={MainLayout}
        path="/add-pet"
      />
      <ProtectedRouteWithLayout
        component={EditPetView}
        exact
        layout={MainLayout}
        path="/edit-pet"
      />
      <ProtectedRouteWithLayout
        component={PetDashboardView}
        exact
        layout={MainLayout}
        path="/pet-profile"
      />
      <ProtectedRouteWithLayout
        component={EditVaccineView}
        exact
        layout={MainLayout}
        path="/edit-vaccine"
      />
      <ProtectedRouteWithLayout
        component={EditMedicineView}
        exact
        layout={MainLayout}
        path="/edit-medicine"
      />
      <ProtectedRouteWithLayout
        component={MedicineInformationView}
        exact
        layout={MainLayout}
        path="/medicine-details"
      />
      <ProtectedRouteWithLayout
        component={VaccineInformationView}
        exact
        layout={MainLayout}
        path="/vaccine-details"
      />
      <ProtectedRouteWithLayout
        component={ConfirmEmailView}
        exact
        layout={MainLayout}
        path="/confirm"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={SingleLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={ForgotPasswordView}
        exact
        layout={SingleLayout}
        path="/forgot-password"
      />
      <RouteWithLayout
        component={ResetPasswordView}
        exact
        layout={SingleLayout}
        path="/reset-password"
      />
      <SignOutRouteWithLayout exact layout={SingleLayout} path="/sign-out" />
      <SignInRouteWithLayout exact layout={SingleLayout} path="/sign-in" />
      <HomeRouteWithLayout exact layout={HomeLayout} path="/" />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
