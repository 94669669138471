import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link, Grid } from "@material-ui/core";
import {
  TermsAndConditionsDialog,
  PrivacyDialog,
  AboutDialog,
  HelpAndContactDialog,
} from "components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    bottom: 0,
    width: "100%",
    textAlign: "center",
  },
}));

const Footer = () => {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState({
    terms: false,
    privacy: false,
    about: false,
    helpAndContact: false,
    report: false,
  });

  const handleOpenDialog = (dialog) => {
    setOpenDialog({
      ...openDialog,
      [dialog]: true,
    });
  };

  const handleCloseDialog = (dialog) => {
    setOpenDialog({
      ...openDialog,
      [dialog]: false,
    });
  };

  return (
    <div className={classes.root}>
      <Grid justify="center" container>
        <Grid item lg={1} md={1} xl={1} xs={12}>
          <Link
            color="inherit"
            href="#"
            underline="none"
            onClick={() => handleOpenDialog("about")}
            variant="body1"
          >
            Sobre
          </Link>
          <AboutDialog
            open={openDialog.about}
            handleClose={() => handleCloseDialog("about")}
          />
        </Grid>

        <Grid item lg={1} md={1} xl={1} xs={12}>
          <Link
            color="inherit"
            href="#"
            underline="none"
            onClick={() => handleOpenDialog("helpAndContact")}
            variant="body1"
          >
            Contato
          </Link>
          <HelpAndContactDialog
            open={openDialog.helpAndContact}
            handleClose={() => handleCloseDialog("helpAndContact")}
          />
        </Grid>

        <Grid item lg={1} md={1} xl={1} xs={12}>
          <Link
            color="inherit"
            href="#"
            underline="none"
            onClick={() => handleOpenDialog("terms")}
            variant="body1"
          >
            Termos
          </Link>
          <TermsAndConditionsDialog
            open={openDialog.terms}
            handleClose={() => handleCloseDialog("terms")}
          />
        </Grid>
        <Grid item lg={1} md={1} xl={1} xs={12}>
          <Link
            color="inherit"
            href="#"
            underline="none"
            onClick={() => handleOpenDialog("privacy")}
            variant="body1"
          >
            Privacidade
          </Link>
          <PrivacyDialog
            open={openDialog.privacy}
            handleClose={() => handleCloseDialog("privacy")}
          />
        </Grid>
        <Grid item lg={2} md={2} xl={2} xs={12}>
          <Link color="inherit" href="/" underline="none" variant="body1">
            VacinaPet &copy; {new Date().getFullYear()}
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
