//TODO Handle error
export function addVaccine(id, name, additionalInfo, veterinary, date, next) {
  return fetch("/api/pets/" + id + "/vaccines", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name,
      additionalInfo: additionalInfo,
      veterinary: veterinary,
      dates: {
        oldDates: [
          {
            date: date,
            nextDate: next,
          },
        ],
        nextDate: next,
      },
      type: "vaccine",
      notify: true,
    }),
  }).then((response) => {
    return response;
  });
}

export function editVaccine(
  petId,
  vaccineId,
  name,
  additionalInfo,
  veterinary
) {
  return fetch("/api/pets/" + petId + "/vaccines", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: vaccineId,
      name: name,
      additionalInfo: additionalInfo,
      veterinary: veterinary,
      type: "vaccine",
      notify: true,
    }),
  }).then((response) => {
    return response;
  });
}

//TODO Handle error
export function getVaccine() {
  return fetch("/api/vaccines", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

export function getOnePetVaccine(petId, medicineId) {
  return fetch(`/api/pets/${petId}/medicine/${medicineId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

//TODO Handle error
export function getUpcomingVaccines() {
  return fetch("/api/upcoming-vaccines", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

export function getPetVaccines(id) {
  return fetch(`/api/pets/${id}/vaccines`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

export function deleteVaccine(petId, vaccineId) {
  return fetch(`/api/pets/${petId}/vaccines/${vaccineId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}
