import React, { useState, useEffect } from "react";
import validate from "validate.js";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Chip,
  Fade,
  IconButton,
  Typography,
  Dialog,
} from "@material-ui/core";
import { dateFormat, Today } from "helpers";
import format from "date-fns/format";
import DateFnsUtils from "@date-io/date-fns";
import ptBRLocale from "date-fns/locale/pt-BR";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import * as Medicine from "../../controller/data/medicine.js";

const schema = {
  date: {
    presence: { allowEmpty: false, message: "is required" },
  },
  next: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  errorMessage: { textAlign: "right", paddingRight: theme.spacing(2) },
}));

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "dd MM yyyy", { locale: this.locale });
  }
}

const UpdateTreatmentButton = (props) => {
  const { handleOpen, component } = props;
  const classes = useStyles();

  if (component == "chip") {
    return (
      <Chip
        classes={{
          root: classes.statusRoot,
          label: classes.statusLabel,
        }}
        label="Adicionar nova data"
        size="medium"
        clickable
        color="primary"
        variant="outlined"
        onClick={handleOpen}
      />
    );
  } else {
    return (
      <IconButton color="primary" size="medium" onClick={handleOpen}>
        <AddCircleIcon />
      </IconButton>
    );
  }
};

const UpdateTreatmentStatusModal = (props) => {
  const { pet, treatment, treatmentType, component } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [formState, setFormState] = useState({
    values: {
      date: Today.date,
      dateValue: Today.string(),
      next: Today.date,
      nextValue: Today.string(),
    },
    touched: {},
    errors: {},
    showError: false,
  });

  const [changedState, setChangedState] = useState({
    date: true,
    next: true,
  });

  useEffect(() => {
    validate.extend(validate.validators.datetime, dateFormat);
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleDateChange = (date, value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        date: date,
        dateValue: value,
      },
    }));
    setChangedState((changedState) => ({
      ...changedState,
      date: true,
    }));
  };

  const handleNextChange = (date, value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        next: date,
        nextValue: value,
      },
    }));
    setChangedState((changedState) => ({
      ...changedState,
      next: true,
    }));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateTreatmentDates = () => {
    Medicine.updateNextMedicineDate(
      pet.id,
      treatment.id,
      formState.values.dateValue,
      formState.values.nextValue
    ).then((response) => {
      if (response && response.ok) {
        setOpen(false);
        window.location.reload();
      } else {
        setFormState((formState) => ({
          ...formState,
          showError: true,
        }));
        if (response) {
          response.text().then((msg) => {
            setFormState((formState) => ({
              ...formState,
              errorMessage: msg,
            }));
          });
        }
      }
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const hasError = (field) => {
    const error = formState.errors[field] ? true : false;
    return error && changedState[field];
  };

  return (
    <div>
      <UpdateTreatmentButton handleOpen={handleOpen} component={component} />
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        disableBackdropClick
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form
              onSubmit={handleUpdateTreatmentDates}
              autoComplete="off"
              noValidate
            >
              <Card>
                <CardHeader
                  title={`Atualizar Datas ${treatmentType}`}
                  subheader={`Data agendada: ${treatment.dates.nextDate}`}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={2} className={classes.container}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Nome do Pet"
                        value={pet.name}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label={`Nome ${treatmentType}`}
                        value={treatment.name}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.container}>
                    <Grid item md={6} xs={12}>
                      <MuiPickersUtilsProvider
                        utils={LocalizedUtils}
                        locale={ptBRLocale}
                      >
                        <KeyboardDatePicker
                          inputProps={{ "data-testid": "date" }}
                          fullWidth
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          value={formState.values.date}
                          label="Data da última dose"
                          placeholder="Data da dose"
                          onChange={handleDateChange}
                          helperText={
                            hasError("date")
                              ? "Por favor, escolha a data da última dose desta vacina para este pet"
                              : ""
                          }
                          error={hasError("date")}
                          name="date"
                          openTo="year"
                          views={["year", "month", "date"]}
                          autoOk={true}
                          required
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <MuiPickersUtilsProvider
                        utils={LocalizedUtils}
                        locale={ptBRLocale}
                      >
                        <KeyboardDatePicker
                          inputProps={{ "data-testid": "next" }}
                          fullWidth
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          value={formState.values.next}
                          label="Data da próxima dose"
                          placeholder="Data da próxima dose"
                          onChange={handleNextChange}
                          helperText={
                            hasError("next")
                              ? "Por favor, escolha a data da próxima dose desta vacina para este pet"
                              : ""
                          }
                          error={hasError("next")}
                          name="next"
                          openTo="year"
                          views={["year", "month", "date"]}
                          autoOk={true}
                          disablePast={true}
                          required
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                {formState.showError && (
                  <Typography
                    className={classes.errorMessage}
                    color="error"
                    variant="body1"
                  >
                    {formState.errorMessage}
                  </Typography>
                )}
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    className={classes.button}
                    data-testid="cancel"
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={classes.button}
                    data-testid="submit"
                    color="primary"
                    variant="contained"
                    disabled={!formState.isValid}
                    onClick={handleUpdateTreatmentDates}
                  >
                    Salvar
                  </Button>
                </Box>
              </Card>
            </form>
          </div>
        </Fade>
      </Dialog>
    </div>
  );
};

class UpdateTreatmentStatus extends React.Component {
  render() {
    return (
      <UpdateTreatmentStatusModal
        pet={this.props.pet}
        treatment={this.props.treatment}
        treatmentType={this.props.treatmentType}
        component={this.props.component}
      />
    );
  }
}
export default UpdateTreatmentStatus;
