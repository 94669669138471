import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Breadcrumbs } from "@material-ui/core";
import PetInformation from "./PetInformation";
import PetMedicineList from "./PetMedicineList";
import PetVaccineList from "./PetVaccineList";
import { Link, Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  element: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

class PetDashboard extends React.Component {
  render() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state.pet === undefined
    ) {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <DashboardPet
          pet={this.props.location.state.pet}
          history={this.props.history}
        />
      );
    }
  }
}

const DashboardPet = (props) => {
  const { pet, history } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <div style={{ display: "flex" }} className={classes.element}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                color="primary"
                variant="h6"
                component={Link}
                to={{
                  pathname: "/dashboard",
                }}
              >
                Dashboard
              </Typography>

              <Typography variant="h6" color="textPrimary">
                {pet.name}
              </Typography>
            </Breadcrumbs>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <div className={classes.element}>
            <PetInformation pet={pet} history={history} />
          </div>
        </Grid>
        <Grid item lg={8} md={8} xl={8} xs={12}>
          <div className={classes.element}>
            <PetVaccineList pet={pet} history={history} />
          </div>
          <div className={classes.element}>
            <PetMedicineList pet={pet} history={history} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

PetDashboard.propTypes = {
  history: PropTypes.object,
};

export default PetDashboard;
