import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import pt from "react-phone-number-input/locale/pt-BR";
import PropTypes from "prop-types";
import validate from "validate.js";
import checked from "../../common/validators";
import { makeStyles } from "@material-ui/core/styles";
import * as Account from "../../controller/data/account.js";
import {
  Button,
  IconButton,
  TextField,
  Link,
  Checkbox,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  TermsAndConditionsDialog,
  PrivacyDialog,
  PasswordInput,
  LoadingDisco,
} from "components";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  passwordConfirmation: {
    equality: { attribute: "password", message: "does not match" },
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  policy: {
    presence: { allowEmpty: false, message: "is required" },
    checked: true,
  },
  privacy: {
    presence: { allowEmpty: false, message: "is required" },
    checked: true,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    position: "static",
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    width: "100%",
    alignItems: "center",
    paddingLeft: "25%",
    paddingRight: "25%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  policy: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  privacy: {
    display: "flex",
    alignItems: "center",
  },
  policyCheckbox: {
    marginLeft: "-10px",
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
  },
}));

const SignUp = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    showError: false,
    errorMessage: "Algo deu errado, por favor confirme os dados.",
    loading: false,
  });

  useEffect(() => {
    validate.validators.checked = checked;
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSignUp = (event) => {
    event.preventDefault();
    setFormState((formState) => ({
      ...formState,
      loading: true,
    }));
    Account.createUser(
      formState.values.email,
      formState.values.password,
      formState.values.phoneNumber
    ).then((response) => {
      if (response.ok) {
        localStorage.setItem("showEmailSnackbar", true);
        window.setTimeout(() => {
          history.push("/dashboard");
        }, 2000);
      } else {
        setFormState((formState) => ({
          ...formState,
          showError: true,
          loading: false,
        }));
        if (response) {
          response.text().then((msg) => {
            setFormState((formState) => ({
              ...formState,
              errorMessage: msg,
            }));
          });
        }
      }
    });
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenTermsAndConditions = (_) => {
    setOpenDialog(true);
  };

  const handleCloseTermsAndConditions = (_) => {
    setOpenDialog(false);
  };

  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false);
  const handleOpenPrivacyDialog = (_) => {
    setOpenPrivacyDialog(true);
  };

  const handleClosePrivacyDialog = (_) => {
    setOpenPrivacyDialog(false);
  };

  const hasError = (field) => {
    return formState.errors[field] && formState.touched[field];
  };

  return (
    <div className={classes.root}>
      {formState.loading && <LoadingDisco />}
      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className={classes.contentBody}>
          <form className={classes.form} onSubmit={handleSignUp}>
            <Typography className={classes.title} variant="h2">
              Cadastre-se
            </Typography>
            <TextField
              className={classes.textField}
              fullWidth
              label="Email"
              name="email"
              onChange={handleChange}
              type="text"
              value={formState.values.email || ""}
              variant="outlined"
            />
            <PasswordInput
              label="Senha"
              name="password"
              value={formState.values.password}
              handleChange={handleChange}
              labelWidth={37}
            />
            <PasswordInput
              label="Confirme a senha"
              name="passwordConfirmation"
              value={formState.values.passwordConfirmation}
              handleChange={handleChange}
              labelWidth={108}
              error={hasError("passwordConfirmation")}
              helperText="As senhas estão diferentes"
            />
            <div className={classes.policy}>
              <Checkbox
                checked={formState.values.policy || false}
                className={classes.policyCheckbox}
                color="primary"
                name="policy"
                onChange={handleChange}
              />
              <Typography
                className={classes.policyText}
                color="textSecondary"
                variant="body1"
              >
                Eu li e concordo com os{" "}
                <Link
                  color="primary"
                  onClick={handleOpenTermsAndConditions}
                  underline="always"
                  variant="h6"
                >
                  Termos e Condições{" "}
                </Link>
                de uso
              </Typography>
              <TermsAndConditionsDialog
                open={openDialog}
                handleClose={handleCloseTermsAndConditions}
              />
            </div>
            <div className={classes.privacy}>
              <Checkbox
                checked={formState.values.privacy || false}
                className={classes.policyCheckbox}
                color="primary"
                name="privacy"
                onChange={handleChange}
              />
              <Typography
                className={classes.policyText}
                color="textSecondary"
                variant="body1"
              >
                Eu li e concordo com a{" "}
                <Link
                  color="primary"
                  onClick={handleOpenPrivacyDialog}
                  underline="always"
                  variant="h6"
                >
                  Política de Privacidade{" "}
                </Link>
              </Typography>
              <PrivacyDialog
                open={openPrivacyDialog}
                handleClose={handleClosePrivacyDialog}
              />
            </div>
            {formState.showError && (
              <Typography
                className={classes.errorMessage}
                color="error"
                variant="body1"
              >
                {formState.errorMessage}
              </Typography>
            )}
            <Button
              className={classes.signUpButton}
              color="primary"
              disabled={!formState.isValid}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Cadastrar
            </Button>
            <Typography color="textSecondary" variant="body1">
              Já possui uma conta?{" "}
              <Link component={RouterLink} to="/sign-in" variant="h6">
                Entre agora
              </Link>
            </Typography>
          </form>
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignUp);
