import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import { Sidebar, Topbar, Footer } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: "100%",
  },
  children: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
}));

const Main = (props) => {
  const { children } = props;

  const classes = useStyles();

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: false,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Hidden lgUp>
        <Sidebar
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant="temporary"
        />
      </Hidden>

      <main className={classes.content}>
        <div className={classes.children}>{children}</div>
        <Hidden mdDown>
          <Footer />
        </Hidden>
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
