import React, { useState } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Chip,
  Tooltip,
} from "@material-ui/core";

import * as Vaccine from "../../controller/data/vaccine.js";
import * as Paginate from "../../helpers/paginate.js";

class PetVaccineList extends React.Component {
  state = {
    vaccines: [],
    pet: this.props.pet,
    isLoaded: false,
  };

  componentDidMount() {
    Vaccine.getPetVaccines(this.state.pet.id).then((response) => {
      if (response) {
        response.json().then((data) => {
          this.setState({
            vaccines: data,
            isLoaded: true,
          });
        });
      }
    });
  }

  render() {
    return (
      <PetListVaccine
        history={this.props.history}
        vaccines={this.state.vaccines}
        pet={this.state.pet}
        isLoaded={this.state.isLoaded}
      />
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  alertMessageDiv: {
    padding: 20,
    textAlign: "center",
  },
  alertMessage: {
    color: theme.palette.alert,
  },
  inner: {
    minWidth: 400,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
  tableRow: {
    cursor: "pointer",
  },
}));

const statusColors = {
  valid: "#17994d",
  expiring: "#ff9100",
  expired: "#ff3d00",
};

const statusLabels = {
  valid: "Em dia",
  expiring: "Perto de expirar",
  expired: "Expirada",
};

const PetListVaccineCardContent = (props) => {
  const { className, vaccines, pet, history, isLoaded, ...rest } = props;

  const classes = useStyles();

  const [limit] = useState(5);
  const [page, setPage] = useState(0);
  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleRowClick = (rowVaccine) => {
    history.push({
      pathname: "/vaccine-details",
      state: { pet: pet, treatment: rowVaccine },
    });
  };

  if (vaccines === undefined || (vaccines.length === 0 && isLoaded)) {
    return (
      <CardContent className={(classes.content, classes.alertMessageDiv)}>
        <Typography className={classes.alertMessage} variant="h5">
          {"Nenhuma vacina cadastrada para este pet."}
        </Typography>
        <Typography className={classes.alertMessage} variant="h5">
          {"Adicione novas vacinas clicando no botão '+' acima."}
        </Typography>
      </CardContent>
    );
  } else {
    return (
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="25%">Nome</TableCell>
                  <TableCell width="25%">Próxima dose</TableCell>
                  <TableCell width="25%">Clínica/Veterinária(o)</TableCell>
                  <TableCell width="25%">Situação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Paginate.paginateData(vaccines, page, limit).map((vaccine) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    data-testid={vaccine.id}
                    key={vaccine.id}
                    onClick={() => handleRowClick(vaccine)}
                  >
                    <TableCell width="25%">{vaccine.name}</TableCell>
                    <TableCell width="25%">{vaccine.dates.nextDate}</TableCell>
                    <TableCell width="25%">{vaccine.veterinary}</TableCell>
                    <TableCell width="25%">
                      <Chip
                        style={{
                          backgroundColor: statusColors[vaccine.state],
                        }}
                        label={statusLabels[vaccine.state]}
                        size="medium"
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={vaccines.length}
          onChangePage={handlePageChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10]}
          labelDisplayedRows={(from = page) =>
            `${from.from}-${from.to === -1 ? from.count : from.to} de ${
              from.count
            }`
          }
        />
      </CardContent>
    );
  }
};

const PetListVaccine = (props) => {
  const { className, vaccines, pet, history, isLoaded, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <Tooltip title="Adicionar nova vacinação">
            <IconButton
              color="primary"
              size="medium"
              component={Link}
              to={{
                pathname: "/add-vaccine",
                origin: "pet-profile",
                state: { pet: pet },
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        }
        title="Vacinas do Pet"
      />
      <Divider />
      <PetListVaccineCardContent
        history={history}
        vaccines={vaccines}
        pet={pet}
        isLoaded={isLoaded}
      />
    </Card>
  );
};

PetListVaccine.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

PetVaccineList.propTypes = {
  className: PropTypes.string,
  pet: PropTypes.object,
  history: PropTypes.object,
};

export default PetVaccineList;
