import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import validate from "validate.js";
import { makeStyles } from "@material-ui/core/styles";
import * as Account from "../../../../controller/data/account.js";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Typography,
} from "@material-ui/core";
import { LoadingDisco, PasswordInput } from "components";
import theme from "theme/index.js";

const schema = {
  password: {
    presence: { allowEmpty: false, message: "Campo obrigatório" },
    length: {
      maximum: 128,
    },
  },
  newPassword: {
    presence: { allowEmpty: false, message: "Campo obrigatório" },
    length: {
      maximum: 128,
    },
  },
  passwordConfirmation: {
    equality: { attribute: "newPassword", message: "Senhas diferentes" },
    presence: { allowEmpty: false, message: "Campo obrigatório" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles(() => ({
  root: {
    position: "static",
  },
  infoMessage: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  saveButton: {
    margin: theme.spacing(1),
  },
}));

const Password = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    showError: false,
    showSuccess: false,
    errorMessage: "Algo deu errado, por favor confirme os dados.",
    loading: false,
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    const value = event.target.value;
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();
    setFormState((formState) => ({
      ...formState,
      loading: true,
    }));
    Account.changePassword(
      formState.values.password,
      formState.values.newPassword
    ).then((response) => {
      setFormState((formState) => ({
        ...formState,
        loading: false,
      }));
      if (response.ok) {
        setFormState((formState) => ({
          ...formState,
          showSuccess: true,
          values: {
            password: "",
            newPassword: "",
            passwordConfirmation: "",
          },
          touched: {},
        }));
        window.setTimeout(() => {
          setFormState((formState) => ({
            ...formState,
            showSuccess: false,
          }));
        }, 5000);
      }
      if (!response.ok) {
        setFormState((formState) => ({
          ...formState,
          showError: true,
        }));
        if (response) {
          response.text().then((msg) => {
            setFormState((formState) => ({
              ...formState,
              errorMessage: msg,
            }));
          });
        }
      }
    });
  };

  const hasError = (field) => {
    return formState.errors[field] && formState.touched[field];
  };

  return (
    <div className={classes.root}>
      <Card {...rest} className={clsx(classes.root, className)}>
        {formState.loading && <LoadingDisco />}
        <form onSubmit={handlePasswordChange}>
          <CardHeader
            subheader="Ao alterar a senha você permanecerá logado"
            title="Alterar senha"
          />
          <Divider />
          <CardContent>
            <PasswordInput
              inputProps={{ "data-testid": "password" }}
              label="Senha antiga"
              name="password"
              value={formState.values.password}
              handleChange={handleChange}
              labelWidth={37}
            />
            <PasswordInput
              inputProps={{ "data-testid": "newPassword" }}
              label="Nova senha"
              name="newPassword"
              value={formState.values.newPassword}
              handleChange={handleChange}
              labelWidth={37}
            />
            <PasswordInput
              inputProps={{ "data-testid": "passwordConfirmation" }}
              label="Confirme nova senha"
              name="passwordConfirmation"
              value={formState.values.passwordConfirmation}
              handleChange={handleChange}
              labelWidth={108}
              error={hasError("passwordConfirmation")}
              helperText="As senhas estão diferentes"
            />
          </CardContent>
          <Divider />
          {formState.showError && (
            <Typography
              className={classes.infoMessage}
              color="error"
              variant="body1"
            >
              {formState.errorMessage}
            </Typography>
          )}

          {formState.showSuccess && (
            <Typography
              className={classes.infoMessage}
              color="primary"
              variant="body1"
            >
              Senha alterada com sucesso!
            </Typography>
          )}
          <CardActions>
            <Button
              data-testid="saveButton"
              className={classes.saveButton}
              color="primary"
              variant="outlined"
              disabled={!formState.isValid}
              type="submit"
            >
              Alterar
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

Password.propTypes = {
  className: PropTypes.string,
  setLoading: PropTypes.func,
};

export default Password;
