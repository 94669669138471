import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import "react-phone-number-input/style.css";
import "react-phone-number-input/style.css";
import * as Account from "../../../../controller/data/account.js";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
} from "@material-ui/core";
import { LoadingDisco } from "components";
import theme from "theme/index.js";

const useStyles = makeStyles(() => ({
  root: {
    position: "static",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4),
  },
  phoneBox: {
    marginTop: theme.spacing(2),
  },
  infoMessage: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  saveButton: {
    margin: theme.spacing(1),
  },
}));

class Notifications extends React.Component {
  state = {
    notifications: "",
    phoneNumber: "",
    loaded: false,
  };

  componentDidMount() {
    Account.getNotifications().then((data) => {
      if (data)
        data.json().then((dataJson) => {
          this.setState({
            notifications: dataJson.channels,
            phoneNumber: dataJson.phoneNumber,
            loaded: true,
          });
        });
    });
  }

  render() {
    if (this.state.loaded) {
      return (
        <NotificationsRender
          {...this.props}
          notifications={this.state.notifications}
          phoneNumber={this.state.phoneNumber}
        />
      );
    }
    return <LoadingDisco />;
  }
}

const NotificationsRender = (props) => {
  const { className, notifications, phoneNumber, ...rest } = props;
  const classes = useStyles();
  const [formState, setFormState] = useState({
    values: {
      email: notifications.email,
    },
    showError: false,
    errorMessage: "Algo deu errado, tente novamente mais tarde.",
    loading: false,
  });

  const handleChange = (event) => {
    event.persist();
    const checked = event.target.checked;
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: checked,
      },
    }));
  };

  const handlePhoneNumberChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        phoneNumber: value,
      },
    }));
  };

  const handleConfigureNotification = (event) => {
    event.preventDefault();
    setFormState((formState) => ({
      ...formState,
      showSuccess: false,
      showError: false,
      loading: true,
    }));
    const notification = {
      email: formState.values.email,
    };
    Account.configureNotification(notification).then((response) => {
      setFormState((formState) => ({
        ...formState,
        loading: false,
      }));
      if (response.ok) {
        setFormState((formState) => ({
          ...formState,
          showSuccess: true,
        }));
        window.setTimeout(() => {
          setFormState((formState) => ({
            ...formState,
            showSuccess: false,
          }));
        }, 5000);
      }
      if (!response.ok) {
        setFormState((formState) => ({
          ...formState,
          showError: true,
        }));
        if (response) {
          response.text().then((msg) => {
            setFormState((formState) => ({
              ...formState,
              errorMessage: msg,
            }));
          });
        }
      }
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      {formState.loading && <LoadingDisco />}
      <form onSubmit={handleConfigureNotification}>
        <CardHeader
          subheader="Selecione a caixa de confirmaçao para receber lembretes de vacinas e medicamentos."
          title="Notificações"
        />
        <Divider />
        <CardContent>
          <Grid container wrap="wrap">
            <Grid className={classes.item} item md={12} sm={6} xs={12}>
              <Grid container wrap="wrap">
                <Grid item md={12} sm={12} xs={12}>
                  <FormControlLabel
                    inputprops={{ "data-testid": "email" }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={formState.values.email || false}
                        name="email"
                        onChange={handleChange}
                      />
                    }
                    label="Desejo receber notificações por e-mail"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {formState.showError && (
          <Typography
            className={classes.infoMessage}
            color="error"
            variant="body1"
          >
            {formState.errorMessage}
          </Typography>
        )}

        {formState.showSuccess && (
          <Typography
            className={classes.infoMessage}
            color="primary"
            variant="body1"
          >
            Notificações salvas com sucesso!
          </Typography>
        )}
        <CardActions spacing={4}>
          <Button
            data-testid="saveButton"
            className={classes.saveButton}
            color="primary"
            variant="outlined"
            type="submit"
          >
            Salvar
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Notifications.propTypes = {
  className: PropTypes.string,
};

export default Notifications;
