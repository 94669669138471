import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/core/styles";
import * as Account from "../../controller/data/account.js";
import { Button, IconButton, TextField, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { LoadingDisco } from "components";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    position: "static",
  },
  grid: {
    height: "100%",
    position: "absolute",
    zIndex: 9,
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    backgroundColor: theme.palette.primary.main,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
    padding: theme.spacing(4),
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  form: {
    width: "100%",
    alignItems: "center",
    paddingLeft: "25%",
    paddingRight: "25%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  policy: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  policyCheckbox: {
    marginLeft: "-14px",
  },
  sendButton: {
    margin: theme.spacing(2, 0),
  },
}));

const ForgotPassword = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    showError: false,
    errorMessage: "Algo deu errado, por favor confirme os dados.",
    loading: false,
    loaded: false,
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    const value = event.target.value;
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSendResetPasswordLink = (event) => {
    event.preventDefault();
    setFormState((formState) => ({
      ...formState,
      loading: true,
    }));
    Account.forgotPassword(formState.values.email).then((response) => {
      setFormState((formState) => ({
        ...formState,
        loading: false,
        loaded: true,
      }));
      if (!response.ok) {
        setFormState((formState) => ({
          ...formState,
          showError: true,
        }));
        if (response) {
          response.text().then((msg) => {
            setFormState((formState) => ({
              ...formState,
              errorMessage: msg,
            }));
          });
        }
      }
    });
  };

  return (
    <div className={classes.root}>
      {formState.loading && <LoadingDisco />}
      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        {(!formState.loaded || formState.showError) && (
          <div className={classes.contentBody}>
            <form
              className={classes.form}
              onSubmit={handleSendResetPasswordLink}
            >
              <Typography className={classes.title} variant="h2">
                Insira seu e-mail de cadastro
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Enviaremos um link para redefinição de senha caso o e-mail seja
                valido em nosso sistema.
              </Typography>
              <TextField
                inputProps={{ "data-testid": "email" }}
                className={classes.textField}
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                type="text"
                value={formState.values.email || ""}
                variant="outlined"
              />

              {formState.showError && (
                <Typography
                  className={classes.errorMessage}
                  color="error"
                  variant="body1"
                >
                  {formState.errorMessage}
                </Typography>
              )}
              <Button
                data-testid="sendButton"
                className={classes.sendButton}
                color="primary"
                disabled={!formState.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Enviar
              </Button>
            </form>
          </div>
        )}
        {formState.loaded && !formState.showError && (
          <div className={classes.contentBody}>
            <div className={classes.form}>
              <Typography className={classes.title} variant="h2">
                Email enviado!
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Verifique sua inbox ou caixa de spam.
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgotPassword);
