import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Link, List, ListItem, Typography } from "@material-ui/core";
import {
  TermsAndConditionsDialog,
  PrivacyDialog,
  AboutDialog,
  HelpAndContactDialog,
  ReportDialog,
} from "components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    bottom: 0,
    position: "fixed",
    width: "100%",
    textAlign: "center",
    left: 0,
  },
  footer: {
    padding: theme.spacing(2),
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Footer = () => {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState({
    terms: false,
    privacy: false,
    about: false,
    helpAndContact: false,
    report: false,
  });

  const handleOpenDialog = (dialog) => {
    setOpenDialog({ ...openDialog, [dialog]: true });
  };

  const handleCloseDialog = (dialog) => {
    setOpenDialog({ ...openDialog, [dialog]: false });
  };

  return (
    <List className={classes.footer}>
      <ListItem className={classes.item} disableGutters>
        <Link
          color="inherit"
          href="#"
          underline="none"
          onClick={() => handleOpenDialog("about")}
          variant="body1"
        >
          Sobre
        </Link>
        <AboutDialog
          open={openDialog.about}
          handleClose={() => handleCloseDialog("about")}
        />
      </ListItem>

      <ListItem className={classes.item} disableGutters>
        <Link
          color="inherit"
          href="#"
          underline="none"
          onClick={() => handleOpenDialog("helpAndContact")}
          variant="body1"
        >
          Ajuda e Contato
        </Link>
        <HelpAndContactDialog
          open={openDialog.helpAndContact}
          handleClose={() => handleCloseDialog("helpAndContact")}
        />
      </ListItem>

      <ListItem className={classes.item} disableGutters>
        <Link
          color="inherit"
          href="#"
          underline="none"
          onClick={() => handleOpenDialog("report")}
          variant="body1"
        >
          Reportar Problema
        </Link>
        <ReportDialog
          open={openDialog.report}
          handleClose={() => handleCloseDialog("report")}
        />
      </ListItem>

      <ListItem className={classes.item} disableGutters>
        <Link
          color="inherit"
          href="#"
          underline="none"
          onClick={() => handleOpenDialog("terms")}
          variant="body1"
        >
          Termos e Condições
        </Link>
        <TermsAndConditionsDialog
          open={openDialog.terms}
          handleClose={() => handleCloseDialog("terms")}
        />
      </ListItem>

      <ListItem className={classes.item} disableGutters>
        <Link
          color="inherit"
          href="#"
          underline="none"
          onClick={() => handleOpenDialog("privacy")}
          variant="body1"
        >
          Privacidade de Dados
        </Link>
        <PrivacyDialog
          open={openDialog.privacy}
          handleClose={() => handleCloseDialog("privacy")}
        />
      </ListItem>

      <ListItem className={classes.item} disableGutters>
        <Link color="inherit" href="/" underline="none" variant="body1">
          VacinaPet &copy; {new Date().getFullYear()}
        </Link>
      </ListItem>
    </List>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
