import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Link } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const TermsAndConditionsDialog = (props) => {
  const { open, handleClose } = props;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Termos e Condições Gerais de Uso
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Os serviços do VacinaPet são fornecidos pela pessoa física Sylvia
            Moss Martinez, titular da propriedade intelectual sobre software,
            website, aplicativos, conteúdos e demais ativos relacionados à
            plataforma VacinaPet.
          </Typography>
          <br />
          <Typography variant="h6">1. Do objeto</Typography>
          <br />
          <Typography gutterBottom>
            A plataforma visa licenciar o uso de seu software, website,
            aplicativos e demais ativos de propriedade intelectual, fornecendo
            ferramentas para auxiliar e dinamizar o dia a dia dos seus usuários.
          </Typography>
          <Typography gutterBottom>
            A plataforma caracteriza-se pela prestação do seguinte serviço:
            cartão online de vacinação para animais domésticos, com a ferramenta
            de notificação com lembrete de vacinação via diversos canais de
            comunicação permitidos pelo usuário.
          </Typography>
          <br />
          <Typography variant="h6">2. Da aceitação</Typography>
          <br />
          <Typography gutterBottom>
            O presente Termo estabelece obrigações contratadas de livre e
            espontânea vontade, por tempo indeterminado, entre a plataforma e as
            pessoas físicas ou jurídicas, usuárias do site.
          </Typography>
          <Typography gutterBottom>
            Ao utilizar a plataforma o usuário aceita integralmente as presentes
            normas e compromete-se a observá-las, sob o risco de aplicação das
            penalidades cabíveis.
          </Typography>
          <Typography gutterBottom>
            A aceitação do presente instrumento é imprescindível para o acesso e
            para a utilização de quaisquer serviços fornecidos pela ferramenta.
            Caso não concorde com as disposições deste instrumento, o usuário
            não deve utilizá-los.
          </Typography>
          <br />
          <Typography variant="h6">3. Do acesso dos usuários</Typography>
          <br />
          <Typography gutterBottom>
            Serão utilizadas todas as soluções técnicas à disposição do
            responsável pela plataforma para permitir o acesso ao serviço 24
            (vinte e quatro) horas por dia, 7 (sete) dias por semana. No
            entanto, a navegação na plataforma ou em alguma de suas páginas
            poderá ser interrompida, limitada ou suspensa para atualizações,
            modificações ou qualquer ação necessária ao seu bom funcionamento.
          </Typography>
          <br />
          <Typography variant="h6">4. Do cadastro</Typography>
          <br />
          <Typography gutterBottom>
            O acesso às funcionalidades da plataforma exigirá a realização de um
            cadastro prévio. Ao se cadastrar o usuário deverá informar dados
            completos, recentes e válidos, sendo de sua exclusiva
            responsabilidade manter referidos dados atualizados, bem como o
            usuário se compromete com a veracidade dos dados fornecidos.
          </Typography>
          <Typography gutterBottom>
            O usuário se compromete a não informar seus dados cadastrais e/ou de
            acesso à plataforma a terceiros, responsabilizando-se integralmente
            pelo uso que deles seja feito.
          </Typography>
          <Typography gutterBottom>
            Menores de 18 anos e aqueles que não possuírem plena capacidade
            civil deverão obter previamente o consentimento expresso de seus
            responsáveis legais para utilização da plataforma e dos serviços ou
            produtos, sendo de responsabilidade exclusiva dos mesmos o eventual
            acesso por menores de idade e por aqueles que não possuem plena
            capacidade civil sem a prévia autorização. Mediante a realização do
            cadastro o usuário declara e garante expressamente ser plenamente
            capaz, podendo exercer e usufruir livremente dos serviços e
            produtos.
          </Typography>
          <Typography gutterBottom>
            O usuário deverá fornecer um endereço de e-mail válido, através do
            qual o site realizará todas as comunicações necessárias. Após a
            confirmação do cadastro, o usuário possuirá um login e uma senha
            pessoal, a qual assegura ao usuário o acesso individual à mesma.
            Desta forma, compete ao usuário exclusivamente a manutenção de
            referida senha de maneira confidencial e segura, evitando o acesso
            indevido às informações pessoais.{" "}
          </Typography>
          <Typography gutterBottom>
            Toda e qualquer atividade realizada com o uso da senha será de
            responsabilidade do usuário, que deverá informar prontamente a
            plataforma em caso de uso indevido da respectiva senha.
          </Typography>
          <Typography gutterBottom>
            Não será permitido ceder, vender, alugar ou transferir, de qualquer
            forma, a conta, que é pessoal e intransferível. Caberá ao usuário
            assegurar que o seu equipamento seja compatível com as
            características técnicas que viabilize a utilização da plataforma e
            dos serviços ou produtos.
          </Typography>
          <Typography gutterBottom>
            O usuário poderá, a qualquer tempo, requerer o cancelamento de seu
            cadastro junto ao site VacinaPet.
          </Typography>
          <Typography gutterBottom>
            O usuário, ao aceitar os Termos de Condições Gerais de Uso e
            Política de Privacidade, autoriza expressamente a plataforma a
            coletar, usar, armazenar, tratar, ceder ou utilizar as informações
            derivadas do uso dos serviços, do site e quaisquer plataformas,
            incluindo todas as informações preenchidas pelo usuário no momento
            em que realizar ou atualizar seu cadastro, além de outras
            expressamente descritas na Política de Privacidade que deverá ser
            autorizada pelo usuário
          </Typography>
          <br />
          <Typography variant="h6">5. Do suporte</Typography>
          <br />
          <Typography gutterBottom>
            Em caso de qualquer dúvida, sugestão ou problema com a utilização da
            plataforma, o usuário poderá entrar em contato com o suporte,
            através do email{" "}
            <Link href="mailto:suporte@vacinapet.com">
              suporte@vacinapet.com
            </Link>
            . Estes serviços de atendimento ao usuário estarão disponíveis nos
            seguintes dias e horários: Segunda a Sexta de 08:00 às 12:00.
          </Typography>
          <br />
          <Typography variant="h6">6. Das responsabilidades</Typography>
          <br />
          <Typography gutterBottom>
            É de responsabilidade do usuário:
            <br />
            a) vícios técnicos originados no próprio sistema do usuário;
            <br />
            b) a correta utilização da plataforma, prezando pela boa
            convivência, pelo respeito e cordialidade entre os usuários;
            <br />
            c) pelo cumprimento e respeito ao conjunto de regras disposto neste
            Termo e Condições Geral de Uso, na respectiva Política de
            Privacidade e na legislação nacional e internacional; <br />
            d) pela proteção aos dados de acesso à sua conta/perfil (login e
            senha). <br />
          </Typography>
          <Typography gutterBottom>
            É de responsabilidade da plataforma VacinaPet:
            <br />
            a) indicar as características do serviço ou produto;
            <br />
            b) os defeitos e vícios encontrados no serviço ou produto oferecido
            desde que lhe tenha dado causa;
            <br />
            c) as informações que foram por ele divulgadas, sendo que os
            comentários ou informações divulgadas por usuários são de inteira
            responsabilidade dos próprios usuários; <br />
          </Typography>
          <Typography gutterBottom>
            A plataforma não se responsabiliza por links externos contidos em
            seu sistema que possam redirecionar o usuário a um ambiente externo
            a sua rede. Usuários da plataforma VacinaPet não poderão incluir
            links externos ou páginas que sirvam para fins comerciais ou
            publicitários ou quaisquer informações ilícitas, violentas,
            polêmicas, pornográficas, xenofóbicas, discriminatórias ou
            ofensivas.
          </Typography>
          <br />
          <Typography variant="h6">
            7. Dos{" "}
            <Link
              href="https://www.jusbrasil.com.br/legislacao/92175/lei-de-direitos-autorais-lei-9610-98"
              rel="noreferrer"
              target="_blank"
              title="Lei nº 9.610, de 19 de fevereiro de 1998."
            >
              direitos autorais
            </Link>
          </Typography>
          <br />
          <Typography gutterBottom>
            O presente Termo e Condições Gerais de Uso concede aos usuários uma
            licença não exclusiva, não transferível e não sublicenciável, para
            acessar e fazer uso da plataforma e dos serviços e produtos por ela
            disponibilizados.
          </Typography>
          <Typography gutterBottom>
            A estrutura do site ou aplicativo, as marcas, logotipos, nomes
            comerciais, layouts, gráficos e design de interface, imagens,
            ilustrações, fotografias, apresentações, vídeos, conteúdos escritos
            e de som e áudio, programas de computador, banco de dados, arquivos
            de transmissão e quaisquer outras informações e direitos de
            propriedade intelectual da razão social Sylvia Moss Martinez,
            observados os termos da{" "}
            <Link
              href="https://www.jusbrasil.com.br/legislacao/91774/código-de-propriedade-industrial-lei-9279-96"
              rel="noreferrer"
              target="_blank"
              title="Lei nº 9.279, de 14 de maio de 1996."
            >
              Lei da Propriedade Industrial
            </Link>
            (Lei nº{" "}
            <Link
              href="https://www.jusbrasil.com.br/legislacao/91774/código-de-propriedade-industrial-lei-9279-96"
              rel="noreferrer"
              target="_blank"
              title="Lei nº 9.279, de 14 de maio de 1996."
            >
              9.279
            </Link>
            /96),
            <Link
              href="https://www.jusbrasil.com.br/legislacao/92175/lei-de-direitos-autorais-lei-9610-98"
              rel="noreferrer"
              target="_blank"
              title="Lei nº 9.610, de 19 de fevereiro de 1998."
            >
              Lei de Direitos Autorais
            </Link>
            (Lei nº{" "}
            <Link
              href="https://www.jusbrasil.com.br/legislacao/92175/lei-de-direitos-autorais-lei-9610-98"
              rel="noreferrer"
              target="_blank"
              title="Lei nº 9.610, de 19 de fevereiro de 1998."
            >
              9.610
            </Link>
            /98) e
            <Link
              href="https://www.jusbrasil.com.br/legislacao/109879/lei-do-software-lei-9609-98"
              rel="noreferrer"
              target="_blank"
              title="Lei nº 9.609 , de 19 de fevereiro de 1998."
            >
              {" "}
              Lei do Software
            </Link>
            (Lei nº{" "}
            <Link
              href="https://www.jusbrasil.com.br/legislacao/109879/lei-do-software-lei-9609-98"
              rel="noreferrer"
              target="_blank"
              title="Lei nº 9.609 , de 19 de fevereiro de 1998."
            >
              9.609
            </Link>
            /98), estão devidamente reservados.
          </Typography>
          <Typography gutterBottom>
            Este Termos e Condições Gerais de Uso não cede ou transfere ao
            usuário qualquer direito, de modo que o acesso não gera qualquer
            direito de propriedade intelectual ao usuário, exceto pela licença
            limitada ora concedida.
          </Typography>
          <Typography gutterBottom>
            O uso da plataforma pelo usuário é pessoal, individual e
            intransferível, sendo vedado qualquer uso não autorizado, comercial
            ou não-comercial. Tais usos consistirão em violação dos direitos de
            propriedade intelectual da razão social Sylvia Moss Martinez,
            puníveis nos termos da legislação aplicável.
          </Typography>
          <br />
          <Typography variant="h6">8. Das sanções</Typography>
          <br />
          <Typography gutterBottom>
            Sem prejuízo das demais medidas legais cabíveis, a razão social
            Sylvia Moss Martinez poderá, a qualquer momento, advertir, suspender
            ou cancelar a conta do usuário: <br />
            a) que violar qualquer dispositivo do presente Termo;
            <br />
            b) que descumprir os seus deveres de usuário;
            <br />
            c) que tiver qualquer comportamento fraudulento, doloso ou que
            ofenda a terceiros.
            <br />
          </Typography>
          <br />
          <Typography variant="h6">9. Da rescisão</Typography>
          <br />
          <Typography gutterBottom>
            A não observância das obrigações pactuadas neste Termos e Condições
            Gerais de Uso ou da legislação aplicável poderá, sem prévio aviso,
            ensejar a imediata rescisão unilateral por parte da razão social
            Sylvia Moss Martinez e o bloqueio de todos os serviços prestados ao
            usuário.
          </Typography>
          <br />
          <Typography variant="h6">10. Das alterações</Typography>
          <br />
          <Typography gutterBottom>
            Os itens descritos no presente instrumento poderão sofrer
            alterações, unilateralmente e a qualquer tempo, por parte de Sylvia
            Moss Martinez, para adequar ou modificar os serviços, bem como para
            atender novas exigências legais. As alterações serão veiculadas pelo
            site VacinaPet e o usuário poderá optar por aceitar o novo conteúdo
            ou por cancelar o uso dos serviços.
          </Typography>
          <br />
          <Typography variant="h6">11. Da política de privacidade</Typography>
          <br />
          <Typography gutterBottom>
            Além do presente Termo, o usuário deverá consentir com as
            disposições contidas na respectiva Política de Privacidade a ser
            apresentada a todos os interessados dentro da interface da
            plataforma.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TermsAndConditionsDialog;
