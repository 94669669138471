import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import * as Account from "../../controller/data/account.js";

class ProtectedRouteWithLayout extends React.Component {
  state = {
    authenticated: false,
    loaded: false,
  };

  componentDidMount() {
    Account.validateSession().then((ok) => {
      this.setState({
        authenticated: ok,
        loaded: true,
      });
    });
  }

  render() {
    if (this.state.authenticated && this.state.loaded) {
      return <ComponentRoute {...this.props} />;
    } else if (this.state.loaded) {
      return <SignInRoute {...this.props} />;
    }
    return null;
  }
}

const ComponentRoute = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

const SignInRoute = (props) => {
  const { path } = props;
  const query = useLocation().search;
  return (
    <Redirect
      to={{
        pathname: "/sign-in",
        state: { from: path + query },
      }}
    />
  );
};

ProtectedRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default ProtectedRouteWithLayout;
