import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// const resources = {
//     en: {
//         translation: {
//             "Welcome to VacinaPet": "We are dedicated to helping keep your pet's health up to date by sending vaccination and medication reminders via email."
//         }
//     },
//     pt: {
//         translation: {
//             "Welcome to VacinaPet": "Nos dedicamos em ajudar a manter a saúde do seu pet em dia, enviando lembretes de vacinação e medicação por e-mail."
//         }
//     }
// };

i18n
    // i18next-http-backend
    // loads translations from your server
    // https://github.com/i18next/i18next-http-backend
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        // resources,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;