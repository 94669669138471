import React from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Tooltip } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "inherit",
    height: "100%",
    position: "static",
  },
  quote: {
    backgroundColor: theme.palette.primary.main,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    flexBasis: "800px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  quoteLogo: {
    textAlign: "center",
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
    marginLeft: "5%",
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  img: {
    opacity: "0.9",
  },
  buttons: {
    width: "100%",
    alignItems: "center",
    paddingTop: "8%",
    paddingBottom: "10%",
    paddingRight: "20%",
    paddingLeft: "20%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    textAlign: "center",
    color: theme.palette.grey,
  },
}));

const Home = (props) => {
  const { t, i18n } = useTranslation();
  const { history } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.quote}>
        <div className={classes.quoteInner}>
          <div className={classes.quoteLogo}>
            <Tooltip title="Olá! Meu nome é Matilda. " aria-label="add">
              <img
                alt="Matilda"
                className={classes.img}
                src="/images/matilda_home_small.png"
                width="19%"
                height="19%"
              />
            </Tooltip>
            <br />
            <img alt="Logo" src="/images/VacinaPet.png" />
            <br />
            <br />
            <br />
          </div>

          <Typography
            className={classes.quoteText}
            color="inherit"
            variant="h3"
          >
            {t('Nos dedicamos em ajudar a manter a saúde do seu pet em dia, enviando lembretes de vacinação e medicação por e-mail.')}
          </Typography>
          <br />
          <div className={classes.buttons}>
            <Button
              color="default"
              fullWidth
              size="large"
              to="/sign-in"
              variant="contained"
              component={Link}
            >
              {t('ENTRAR')}
            </Button>
            <br />
            <br />
            <Button
              color="default"
              fullWidth
              size="large"
              to="/sign-up"
              variant="contained"
              component={Link}
            >
              {t('CRIAR NOVA CONTA')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Home);
