import React, { useState } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import * as Medicine from "../../controller/data/medicine.js";
import * as Paginate from "../../helpers/paginate.js";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Chip,
  Tooltip,
} from "@material-ui/core";

class PetMedicineList extends React.Component {
  state = {
    medicine: [],
    pet: this.props.pet,
    isLoaded: false,
  };

  componentDidMount() {
    Medicine.getPetMedicine(this.state.pet.id).then((response) => {
      if (response) {
        response.json().then((data) => {
          this.setState({
            medicine: data,
            isLoaded: true,
          });
        });
      }
    });
  }

  render() {
    return (
      <PetListMedicine
        history={this.props.history}
        medicine={this.state.medicine}
        pet={this.state.pet}
        isLoaded={this.state.isLoaded}
      />
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  alertMessageDiv: {
    padding: 20,
    textAlign: "center",
  },
  alertMessage: {
    color: theme.palette.alert,
  },
  inner: {
    minWidth: 400,
  },
  status: {
    marginRight: theme.spacing(1),
  },
  tableRow: {
    cursor: "pointer",
  },
}));

const statusColors = {
  valid: "#17994d",
  expiring: "#ff9100",
  expired: "#ff3d00",
};

const statusLabels = {
  valid: "Em dia",
  expiring: "Perto de expirar",
  expired: "Expirada",
};

const PetListMedicineCardContent = (props) => {
  const { className, medicine, pet, history, isLoaded, ...rest } = props;

  const classes = useStyles();

  const [limit] = useState(5);
  const [page, setPage] = useState(0);
  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleRowClick = (rowMedicine) => {
    history.push({
      pathname: "/medicine-details",
      state: { pet: pet, treatment: rowMedicine },
    });
  };

  if ((medicine === undefined || medicine.length === 0) && isLoaded) {
    return (
      <CardContent className={(classes.content, classes.alertMessageDiv)}>
        <Typography className={classes.alertMessage} variant="h5">
          {"Nenhum medicamento cadastrado para este pet. "}
        </Typography>
        <Typography className={classes.alertMessage} variant="h5">
          {"Adicione novos medicamentos clicando no botão '+' acima."}
        </Typography>
      </CardContent>
    );
  } else {
    return (
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="25%">Nome</TableCell>
                  <TableCell width="25%">Próxima dose</TableCell>
                  <TableCell width="25%">Clínica/Veterinária(o)</TableCell>
                  <TableCell width="25%">Situação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Paginate.paginateData(medicine, page, limit).map(
                  (medicine) => (
                    <TableRow
                      className={classes.tableRow}
                      data-testid={medicine.id}
                      hover
                      key={medicine.id}
                      onClick={() => handleRowClick(medicine)}
                    >
                      <TableCell width="25%">{medicine.name}</TableCell>
                      <TableCell width="25%">
                        {medicine.dates.nextDate}
                      </TableCell>
                      <TableCell width="25%">{medicine.veterinary}</TableCell>
                      <TableCell width="25%" align="justify">
                        <Chip
                          style={{
                            backgroundColor: statusColors[medicine.state],
                          }}
                          label={statusLabels[medicine.state]}
                          size="medium"
                          color="primary"
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={medicine.length}
          onChangePage={handlePageChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10]}
          labelDisplayedRows={(from = page) =>
            `${from.from}-${from.to === -1 ? from.count : from.to} de ${
              from.count
            }`
          }
        />
      </CardContent>
    );
  }
};

const PetListMedicine = (props) => {
  const { className, medicine, pet, history, isLoaded, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <Tooltip title="Adicionar nova medicação">
            <IconButton
              color="primary"
              size="medium"
              component={Link}
              to={{
                pathname: "/add-medicine",
                origin: "pet-profile",
                state: { pet: pet },
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        }
        title="Medicamentos do Pet"
      />
      <Divider />
      <PetListMedicineCardContent
        history={history}
        medicine={medicine}
        pet={pet}
        isLoaded={isLoaded}
      />
    </Card>
  );
};

PetListMedicine.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

PetMedicineList.propTypes = {
  className: PropTypes.string,
  pet: PropTypes.object,
  history: PropTypes.object,
};

export default PetMedicineList;
