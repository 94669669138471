//TODO Handle error
export function addMedicine(
  id,
  name,
  additionalInfo,
  veterinary,
  date,
  next,
  notify
) {
  return fetch("/api/pets/" + id + "/medicine", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name,
      additionalInfo: additionalInfo,
      veterinary: veterinary,
      dates: {
        oldDates: [
          {
            date: date,
            nextDate: next,
          },
        ],
        nextDate: next,
      },
      type: "medicine",
      notify: notify,
    }),
  }).then((response) => {
    return response;
  });
}

export function editMedicine(
  petId,
  medicineId,
  name,
  additionalInfo,
  veterinary,
  notify
) {
  return fetch("/api/pets/" + petId + "/medicine", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: medicineId,
      name: name,
      additionalInfo: additionalInfo,
      veterinary: veterinary,
      type: "medicine",
      notify: notify,
    }),
  }).then((response) => {
    return response;
  });
}

//TODO Handle error
export function getMedicine() {
  return fetch("/api/medicine", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

export function getOnePetMedicine(petId, medicineId) {
  return fetch(`/api/pets/${petId}/medicine/${medicineId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

//TODO Handle error
export function getUpcomingMedicine() {
  return fetch("/api/upcoming-medicine", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

export function getPetMedicine(id) {
  return fetch(`/api/pets/${id}/medicine`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

export function deleteMedicine(petId, medicineId) {
  return fetch(`/api/pets/${petId}/medicine/${medicineId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

export function updateNextMedicineDate(petId, medicineId, date, next) {
  return fetch(`/api/pets/${petId}/medicine/${medicineId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      date: date,
      nextDate: next,
    }),
  }).then((response) => {
    return response;
  });
}
