import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import { Footer } from "./components";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    position: "static",
  },
  content: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  grid: {
    height: "100%",
    position: "absolute",
    zIndex: 9,
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    backgroundColor: theme.palette.primary.main,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    flexBasis: "600px",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  quoteLogo: {
    textAlign: "center",
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
    marginLeft: "5%",
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  footer: {
    alignSelf: "flex-end",
  },
  img: {
    opacity: "0.9",
  },
}));

const Single = (props) => {
  const { children } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <div className={classes.quoteLogo}>
                  <Tooltip title="Olá! Meu nome é Matilda. " aria-label="add">
                    <img
                      alt="Matilda"
                      className={classes.img}
                      src="/images/matilda_home_small.png"
                      width="19%"
                      height="19%"
                    />
                  </Tooltip>
                  <br />
                  <img alt="Logo" src="/images/VacinaPet.png" />
                  <br />
                  <br />
                  <br />
                </div>

                <Typography
                  className={classes.quoteText}
                  color="inherit"
                  variant="h3"
                >
                  {t('Nos dedicamos em ajudar a manter a saúde do seu pet em dia, enviando lembretes de vacinação e medicação por e-mail.')}
                </Typography>
                <br />
              </div>
            </div>
          </Grid>

          <Grid
            container
            item
            lg={7}
            xs={12}
            className={classes.contentChildren}
          >
            <Grid item lg={12} xs={12}>
              {children}
            </Grid>
            <Grid item lg={12} xs={12} className={classes.footer}>
              <Footer />
            </Grid>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

Single.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Single;
