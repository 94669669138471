import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Link } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography align="center" variant="h5">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const HelpAndContactDialog = (props) => {
  const { open, handleClose } = props;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Ajuda e Contato
        </DialogTitle>
        <DialogContent dividers>
          <Typography paragraph variant="h6">
            Procurando ajuda?
          </Typography>
          <Typography paragraph>
            Estamos aqui para o que der e vier, e por isso se precisar da nossa
            ajuda, basta enviar uma mensagem pra gente no e-mail{" "}
            <Link href="meajuda@vacinapet.com">meajuda@vacinapet.com</Link>.{" "}
            <br />
            Vamos tentar te responder o mais rápido possível!
          </Typography>

          <Typography paragraph variant="h6">
            Precisa falar com a gente?
          </Typography>
          <Typography paragraph>
            Mande uma mensagem pra gente no e-mail{" "}
            <Link href="contato@vacinapet.com">contato@vacinapet.com</Link>.
          </Typography>

          <Typography paragraph variant="h6">
            Dicas para o envio de e-mail
          </Typography>
          <Typography paragraph>
            Conteúdos de e-mail com os maiores detalhes possíveis nos ajudará a
            entender melhor e mais rápido a sua mensagem, e portanto, a resposta
            deverá ser mais rápida também.
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default HelpAndContactDialog;
