import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Link } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography align="center" variant="h5">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AboutDialog = (props) => {
  const { open, handleClose } = props;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Quem somos e porque estamos aqui!
        </DialogTitle>
        <DialogContent dividers>
          <br />
          <Typography paragraph>
            O VacinaPet foi criado por nós, Sylvia e Bianca, duas pessoas donas
            de 5 pets: Matilda (cachorro), Valente, Luna, Rachel e Quinn
            (gatos). Imagina a dificuldade de manter lembretes de vacinação e
            medicação de cada um!
          </Typography>
          <Typography paragraph>
            Bom, para facilitar nossa vida, decidimos criar um sistema para
            centralizar toda informação sobre a saúde dos nossos pets e claro,
            para nos enviar lembretes quando uma vacinação ou medicação está
            para vencer. Incrível, né?
          </Typography>
          <Typography paragraph>
            Informação centralizada e lembretes automáticos facilitaram tanto a
            nossa vida, que decidimos compartilhar o nosso sistema com o resto
            do mundo criando o <b>VacinaPet</b>!
          </Typography>
          <Typography paragraph variant="h6">
            O VacinaPet é um sistema onde você mantém informações dos seus pets,
            tal como vacinação e medicação, e a parte chata mas necessária de
            conferir as datas da próxima dosagem, fica com a gente. :)
          </Typography>
          <Typography paragraph>
            Vale ressaltar que o VacinaPet <b>não substitui</b> o cartão de
            vacina físico do seu pet. O VacinaPet é um sistema que você deve
            manter atualizado de acordo com o cartão de vacina para que possa
            usufruir corretamente do que o nosso sistema propõe.
          </Typography>
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AboutDialog;
