import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Link } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PrivacyDialog = (props) => {
  const { open, handleClose } = props;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Política de Privacidade
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            No VacinaPet, privacidade e segurança são prioridades e nos
            comprometemos com a transparência do tratamento de dados pessoais
            dos nossos usuários/clientes. Por isso, esta presente Política de
            Privacidade estabelece como é feita a coleta, uso e transferência de
            informações de clientes ou outras pessoas que acessam ou usam nosso
            site.
          </Typography>
          <Typography gutterBottom>
            Ao utilizar nossos serviços, você entende que coletaremos e usaremos
            suas informações pessoais nas formas descritas nesta Política, sob
            as normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das
            disposições consumeristas da Lei Federal 8078/1990 e as demais
            normas do ordenamento jurídico brasileiro aplicáveis.
          </Typography>
          <Typography gutterBottom>
            Dessa forma, o VacinaPet e as responsáveis pelo sistema, no papel de
            Controladora de Dados, obriga-se ao disposto na presente Política de
            Privacidade.
          </Typography>

          <br />
          <Typography variant="h6">
            1. Quais dados coletamos sobre você e para qual finalidade?
          </Typography>
          <br />
          <Typography gutterBottom>
            Nosso site coleta e utiliza alguns dados pessoais seus, de forma a
            viabilizar a prestação de serviços e aprimorar a experiência de uso.
          </Typography>

          <br />
          <Typography variant="h6">
            1.1. Dados pessoais fornecidos pelo titular
          </Typography>
          <br />
          <Typography gutterBottom>
            - Endereço de e-mail: para a finalidade de autenticação no sistema e
            para envio de notificações com lembretes de vacinas e medicamento, e
            também informações importantes sobre o sistema VacinaPet.
          </Typography>

          <br />
          <Typography variant="h6">
            1.2. Dados pessoais coletados automaticamente
          </Typography>
          <br />
          <Typography gutterBottom>
            Não coletamos nenhum dado pessoal automaticamente.
          </Typography>

          <br />
          <Typography variant="h6">2. Como coletamos os seus dados?</Typography>
          <br />
          <Typography gutterBottom>
            É a partir do seu consentimento que tratamos os seus dados pessoais.
            O consentimento é a manifestação livre, informada e inequívoca pela
            qual você autoriza o VacinaPet a tratar seus dados. Assim, em
            consonância com a Lei Geral de Proteção de Dados, seus dados só
            serão coletados, tratados e armazenados mediante prévio e expresso
            consentimento.
          </Typography>
          <Typography gutterBottom>
            O seu consentimento será obtido de forma específica para cada
            finalidade acima descrita, evidenciando o compromisso de
            transparência e boa-fé do VacinaPet para com seus usuários/clientes,
            seguindo as regulações legislativas pertinentes.
          </Typography>
          <Typography gutterBottom>
            Ao utilizar os serviços do VacinaPet e fornecer seus dados pessoais,
            você está ciente e consentindo com as disposições desta Política de
            Privacidade, além de conhecer seus direitos e como exercê-los. A
            qualquer tempo e sem nenhum custo, você poderá revogar seu
            consentimento.
          </Typography>
          <Typography gutterBottom>
            É importante destacar que a revogação do consentimento para o
            tratamento dos dados pode implicar a impossibilidade da performance
            adequada de alguma funcionalidade do site que dependa da operação.
            Tais consequências serão informadas previamente.
          </Typography>

          <br />
          <Typography variant="h6">3. Quais são os seus direitos?</Typography>
          <br />
          <Typography gutterBottom>
            O VacinaPet assegura a seus usuários/clientes seus direitos de
            titular previstos no artigo 18 da Lei Geral de Proteção de Dados.
            Dessa forma, você pode, de maneira gratuita e a qualquer tempo:
          </Typography>
          <Typography gutterBottom>
            - <b>Confirmar a existência de tratamento de dados</b>, de maneira
            simplificada ou em formato claro e completo.
          </Typography>
          <Typography gutterBottom>
            - <b>Acessar seus dados</b>, podendo solicitá-los em uma cópia
            legível sob forma impressa ou por meio eletrônico, seguro e idôneo.
          </Typography>
          <Typography gutterBottom>
            - <b>Corrigir seus dados</b>, ao solicitar a edição, correção ou
            atualização destes.
          </Typography>
          <Typography gutterBottom>
            - <b>Limitar seus dados</b> quando desnecessários, excessivos ou
            tratados em desconformidade com a legislação através da
            anonimização, bloqueio ou eliminação.
          </Typography>
          <Typography gutterBottom>
            - <b>Solicitar a portabilidade de seus dados</b>, através de um
            relatório de dados cadastrais que o VacinaPet trata a seu respeito.
          </Typography>
          <Typography gutterBottom>
            - <b>Eliminar seus dados</b> tratados a partir de seu consentimento,
            exceto nos casos previstos em lei.
          </Typography>
          <Typography gutterBottom>
            - <b>Revogar seu consentimento</b>, desautorizando o tratamento de
            seus dados.
          </Typography>
          <Typography gutterBottom>
            -{" "}
            <b>
              Informar-se sobre a possibilidade de não fornecer seu
              consentimento
            </b>{" "}
            e sobre as consequências da negativa.
          </Typography>

          <br />
          <Typography variant="h6">
            4. Como você pode exercer seus direitos de titular?
          </Typography>
          <br />
          <Typography gutterBottom>
            Para exercer seus direitos de titular, você deve entrar em contato
            com o VacinaPet através do endereço de e-mail{" "}
            <Link href="mailto:privacidade@vacinapet.com">
              privacidade@vacinapet.com
            </Link>
          </Typography>
          <Typography gutterBottom>
            De forma a garantir a sua correta identificação como titular dos
            dados pessoais objeto da solicitação, é possível que solicitemos
            documentos ou demais comprovações que possam comprovar sua
            identidade. Nessa hipótese, você será informado previamente.
          </Typography>

          <br />
          <Typography variant="h6">
            5. Como e por quanto tempo seus dados serão armazenados?
          </Typography>
          <br />
          <Typography gutterBottom>
            Seus dados pessoais coletados pelo VacinaPet serão utilizados e
            armazenados durante o tempo necessário para a prestação do serviço
            ou para que as finalidades elencadas na presente Política de
            Privacidade sejam atingidas, considerando os direitos dos titulares
            dos dados e dos controladores.
          </Typography>
          <Typography gutterBottom>
            De modo geral, seus dados serão mantidos enquanto a relação
            contratual entre você e o VacinaPet perdurar. Findado o período de
            armazenamento dos dados pessoais, estes serão excluídos de nossas
            bases de dados ou anonimizados, ressalvadas as hipóteses legalmente
            previstas no artigo 16 da lei geral de proteção de dados, a saber:
          </Typography>
          <Typography gutterBottom>
            I – cumprimento de obrigação legal ou regulatória pelo controlador;{" "}
            <br />
            II – estudo por órgão de pesquisa, garantida, sempre que possível, a
            anonimização dos dados pessoais; <br />
            III – transferência a terceiro, desde que respeitados os requisitos
            de tratamento de dados dispostos nesta Lei; ou <br />
            IV – uso exclusivo do controlador, vedado seu acesso por terceiro, e
            desde que anonimizados os dados. <br />
          </Typography>
          <Typography gutterBottom>
            Isto é, informações pessoais sobre você que sejam imprescindíveis
            para o cumprimento de determinações legais, judiciais e
            administrativas e/ou para o exercício do direito de defesa em
            processos judiciais e administrativos serão mantidas, a despeito da
            exclusão dos demais dados.{" "}
          </Typography>
          <Typography gutterBottom>
            O armazenamento de dados coletados pelo VacinaPet reflete o nosso
            compromisso com a segurança e privacidade dos seus dados. Empregamos
            medidas e soluções técnicas de proteção aptas a garantir a
            confidencialidade, integridade e inviolabilidade dos seus dados.
            Além disso, também contamos com medidas de segurança apropriadas aos
            riscos e com controle de acesso às informações armazenadas.
          </Typography>

          <br />
          <Typography variant="h6">
            6. O que fazemos para manter seus dados seguros?
          </Typography>
          <br />
          <Typography gutterBottom>
            Para mantermos suas informações pessoais seguras, usamos ferramentas
            físicas, eletrônicas e gerenciais orientadas para a proteção da sua
            privacidade. Aplicamos essas ferramentas levando em consideração a
            natureza dos dados pessoais coletados, o contexto e a finalidade do
            tratamento e os riscos que eventuais violações gerariam para os
            direitos e liberdades do titular dos dados coletados e tratados.
          </Typography>
          <Typography gutterBottom>
            Entre as medidas que adotamos, destacamos as seguintes:
          </Typography>
          <Typography gutterBottom>
            {" "}
            - Apenas pessoas autorizadas têm acesso a seus dados pessoais <br />
            - O acesso a seus dados pessoais é feito somente após o compromisso
            de confidencialidade <br />- Seus dados pessoais são armazenados em
            ambiente seguro e idôneo. <br />
          </Typography>
          <Typography gutterBottom>
            O VacinaPet se compromete a adotar as melhores posturas para evitar
            incidentes de segurança. Contudo, é necessário destacar que nenhuma
            página virtual é inteiramente segura e livre de riscos. É possível
            que, apesar de todos os nossos protocolos de segurança, problemas de
            culpa exclusivamente de terceiros ocorram, como ataques cibernéticos
            de hackers, ou também em decorrência da negligência ou imprudência
            do próprio usuário/cliente.
          </Typography>
          <Typography gutterBottom>
            Em caso de incidentes de segurança que possam gerar risco ou dano
            relevante para você ou qualquer um de nossos usuários/clientes,
            comunicaremos aos afetados e a Autoridade Nacional de Proteção de
            Dados sobre o ocorrido, em consonância com as disposições da Lei
            Geral de Proteção de Dados.
          </Typography>

          <br />
          <Typography variant="h6">
            7. Com quem seus dados podem ser compartilhados?
          </Typography>
          <br />
          <Typography gutterBottom>
            Tendo em vista a preservação de sua privacidade, o VacinaPet não
            compartilhará seus dados pessoais com nenhum terceiro.{" "}
          </Typography>
          <Typography gutterBottom>
            Além disso, existem outras hipóteses em que seus dados poderão ser
            compartilhados, que são:
          </Typography>
          <Typography gutterBottom>
            I – Determinação legal, requerimento, requisição ou ordem judicial,
            com autoridades judiciais, administrativas ou governamentais
            competentes. <br />
            II – Caso de movimentações societárias, como fusão, aquisição e
            incorporação, de forma automática <br />
            III – Proteção dos direitos do VacinaPet em qualquer tipo de
            conflito, inclusive os de teor judicial. <br />
          </Typography>

          <br />
          <Typography variant="h6">
            7.1. Transferência internacional de dados
          </Typography>
          <br />
          <Typography gutterBottom>
            Alguns dos terceiros com quem compartilhamos seus dados podem ser
            localizados ou possuir instalações localizadas em países
            estrangeiros. Nessas condições, de toda forma, seus dados pessoais
            estarão sujeitos à Lei Geral de Proteção de Dados e às demais
            legislações brasileiras de proteção de dados. Nesse sentido, o
            VacinaPet se compromete a sempre adotar eficientes padrões de
            segurança cibernética e de proteção de dados, nos melhores esforços
            de garantir e cumprir as exigências legislativas.
          </Typography>
          <Typography gutterBottom>
            Ao concordar com essa Política de Privacidade, você concorda com
            esse compartilhamento, que se dará conforme as finalidades descritas
            no presente instrumento.
          </Typography>

          <br />
          <Typography variant="h6">8. Cookies ou dados de navegação</Typography>
          <br />
          <Typography gutterBottom>
            Cookies são arquivos de texto enviados pela plataforma ao seu
            computador que armazenam informações relacionadas à navegação do
            site. O VacinaPet faz uso apenas de JWT cookies, utilizados para
            autenticação durante o uso do sistema.
          </Typography>
          <Typography gutterBottom>
            O JWT é um cookie essencial para o funcionamento do sistema e
            portanto não é possível removê-lo enquanto navegar pelas páginas
            autenticadas do VacinaPet.
          </Typography>

          <br />
          <Typography variant="h6">
            9. Alteração desta Política de Privacidade
          </Typography>
          <br />
          <Typography gutterBottom>
            A atual versão da Política de Privacidade foi formulada e atualizada
            pela última vez em 23 de Janeiro de 2021.
          </Typography>
          <Typography gutterBottom>
            Reservamos o direito de modificar essa Política de Privacidade a
            qualquer tempo, principalmente em função da adequação a eventuais
            alterações feitas em nosso site ou em âmbito legislativo.
            Recomendamos que você a revise com frequência.
          </Typography>
          <Typography gutterBottom>
            Eventuais alterações entrarão em vigor a partir de sua publicação em
            nosso site e sempre lhe notificaremos acerca das mudanças ocorridas.
          </Typography>
          <Typography gutterBottom>
            Ao utilizar nossos serviços e fornecer seus dados pessoais após tais
            modificações, você às consente.{" "}
          </Typography>

          <br />
          <Typography variant="h6">10. Responsabilidade</Typography>
          <br />
          <Typography gutterBottom>
            O VacinaPet prevê a responsabilidade dos agentes que atuam nos
            processos de tratamento de dados, em conformidade com os artigos 42
            ao 45 da Lei Geral de Proteção de Dados.
          </Typography>
          <Typography gutterBottom>
            Nos comprometemos em manter esta Política de Privacidade atualizada,
            observando suas disposições e zelando por seu cumprimento. Além
            disso, também assumimos o compromisso de buscar condições técnicas e
            organizativas seguramente aptas a proteger todo o processo de
            tratamento de dados.
          </Typography>
          <Typography gutterBottom>
            Caso a Autoridade Nacional de Proteção de Dados exija a adoção de
            providências em relação ao tratamento de dados realizado pelo
            VacinaPet, comprometemo-nos a seguí-las.{" "}
          </Typography>

          <br />
          <Typography variant="h6">10.1 Isenção de responsabilidade</Typography>
          <br />
          <Typography gutterBottom>
            Conforme mencionado no Tópico 6, embora adotemos elevados padrões de
            segurança a fim de evitar incidentes, não há nenhuma página virtual
            inteiramente livre de riscos. Nesse sentido, o VacinaPet não se
            responsabiliza por:
          </Typography>
          <Typography gutterBottom>
            I – Quaisquer consequências decorrentes da negligência, imprudência
            ou imperícia dos usuários em relação a seus dados individuais.
            Garantimos e nos responsabilizamos apenas pela segurança dos
            processos de tratamento de dados e do cumprimento das finalidades
            descritas no presente instrumento. Destacamos que a responsabilidade
            em relação à confidencialidade dos dados de acesso é do usuário.
          </Typography>
          <Typography gutterBottom>
            II – Ações maliciosas de terceiros, como ataques de hackers, exceto
            se comprovada conduta culposa ou deliberada do VacinaPet. Destacamos
            que em caso de incidentes de segurança que possam gerar risco ou
            dano relevante para você ou qualquer um de nossos usuários/clientes,
            comunicaremos aos afetados e à Autoridade Nacional de Proteção de
            Dados sobre o ocorrido e cumpriremos as providências necessárias.
          </Typography>
          <Typography gutterBottom>
            III – Inveracidade das informações inseridas pelo usuário/cliente
            nos registros necessários para a utilização dos serviços do
            VacinaPet; quaisquer consequências decorrentes de informações falsas
            ou inseridas de má-fé são de inteiramente responsabilidade do
            usuário/cliente.
          </Typography>

          <br />
          <Typography variant="h6">
            11. Encarregado de Proteção de Dados
          </Typography>
          <br />
          <Typography gutterBottom>
            O VacinaPet disponibiliza o seguinte meio para que você possa entrar
            em contato conosco para exercer seus direitos de titular, tais como
            tirar dúvidas sobre esta Política de Privacidade:{" "}
            <Link href="mailto:privacidade@vacinapet.com">
              privacidade@vacinapet.com
            </Link>
            .
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PrivacyDialog;
