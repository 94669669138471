import moment from "moment";

const dateFormat = {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function (value, options) {
    return +moment.utc(value);
  },
  // Input is a unix timestamp
  format: function (value, options) {
    return moment.utc(value).format("DD/MM/YYYY");
  },
};

export default dateFormat;
