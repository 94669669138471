import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import * as Account from "../../controller/data/account.js";
import { LoadingDisco } from "components";

class SignOutRouteWithLayout extends React.Component {
  state = {
    loaded: false,
  };
  componentDidMount() {
    Account.signOut().then((ok) => {
      if (ok) this.setState({ loaded: true });
    });
  }
  render() {
    if (this.state.loaded) {
      return <Redirect to="/sign-in" />;
    }
    return <LoadingComponent layout={this.props.layout} />;
  }
}

const LoadingComponent = (props) => {
  const { layout: Layout } = props;
  return (
    <Layout>
      <LoadingDisco />
    </Layout>
  );
};

SignOutRouteWithLayout.propTypes = {
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default SignOutRouteWithLayout;
