//TODO Handle error
export function getPets(url) {
  if (!url) {
    url = "/api/pets";
  }

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

export function getAllPets() {
  const url = "/api/pets?limit=0";
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

//TODO Handle error
export function addPet(name, species, sex, birthdate) {
  return fetch("/api/pets", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name,
      species: species,
      sex: sex,
      birthdate: birthdate,
    }),
  }).then((response) => {
    return response;
  });
}

//TODO Handle error
export function editPet(id, name, species, sex, birthdate) {
  return fetch("/api/pets", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: id,
      name: name,
      species: species,
      sex: sex,
      birthdate: birthdate,
    }),
  }).then((response) => {
    return response;
  });
}

export function deletePet(id) {
  return fetch(`/api/pets/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}
