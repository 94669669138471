import React, { useState, useEffect } from "react";
import validate from "validate.js";
import PropTypes from "prop-types";
import * as Vaccine from "../../../controller/data/vaccine.js";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  Breadcrumbs,
} from "@material-ui/core";

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  veterinary: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  element: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

class EditVaccine extends React.Component {
  render() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state.pet === undefined ||
      this.props.location.state.vaccine === undefined
    ) {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <EditVaccineForm
          pet={this.props.location.state.pet}
          history={this.props.history}
          vaccine={this.props.location.state.vaccine}
          origin={this.props.location.origin}
        />
      );
    }
  }
}

const EditVaccineForm = (props) => {
  const { history, pet, vaccine, origin } = props;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      name: vaccine.name,
      additionalInfo: vaccine.additionalInfo,
      veterinary: vaccine.veterinary,
      date: vaccine.date,
      next: vaccine.next,
    },
    touched: {},
    errors: {},
    showError: false,
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleEditVaccine = (event) => {
    event.preventDefault();

    Vaccine.editVaccine(
      pet.id,
      vaccine.id,
      formState.values.name,
      formState.values.additionalInfo,
      formState.values.veterinary
    ).then((response) => {
      if (response.ok) {
        history.push({
          pathname: "/vaccine-details",
          state: { pet: pet, treatment: vaccine },
        });
      } else {
        setFormState((formState) => ({
          ...formState,
          showError: true,
        }));
        if (response) {
          response.text().then((msg) => {
            setFormState((formState) => ({
              ...formState,
              errorMessage: msg,
            }));
          });
        }
      }
    });
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container lg={12} md={12} xl={12} xs={12}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <div style={{ display: "flex" }} className={classes.element}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                color="primary"
                variant="h6"
                component={Link}
                to={{
                  pathname: "/dashboard",
                }}
              >
                Dashboard
              </Typography>

              {origin === "pet-profile" ? (
                <Typography
                  component={Link}
                  variant="h6"
                  color="primary"
                  to={{
                    pathname: "/pet-profile",
                    state: {
                      pet: pet,
                    },
                  }}
                >
                  {pet.name}
                </Typography>
              ) : null}

              <Typography
                component={Link}
                variant="h6"
                color="primary"
                to={{
                  pathname: "/vaccine-details",
                  state: {
                    pet: pet,
                    treatment: vaccine,
                  },
                }}
              >
                {vaccine.name}
              </Typography>

              <Typography variant="h6" color="textPrimary">
                Editar
              </Typography>
            </Breadcrumbs>
          </div>
        </Grid>
      </Grid>
      <form
        onSubmit={handleEditVaccine}
        className={classes.element}
        autoComplete="off"
        noValidate
      >
        <Card>
          <CardHeader title="Editar Vacina" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  inputProps={{
                    "data-testid": "id",
                    readOnly: true,
                    disabled: true,
                  }}
                  fullWidth
                  label="Nome do Pet"
                  value={pet.name}
                  name="id"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  inputProps={{ "data-testid": "name" }}
                  fullWidth
                  placeholder="Nome da Vacina"
                  label="Nome da Vacina"
                  name="name"
                  required
                  value={formState.values.name || ""}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  inputProps={{ "data-testid": "additionalInfo" }}
                  fullWidth
                  placeholder="Informações Adicionais"
                  label="Informações Adicionais"
                  name="additionalInfo"
                  value={formState.values.additionalInfo || ""}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  inputProps={{ "data-testid": "veterinary" }}
                  fullWidth
                  placeholder="Nome da(o) Veterinária(o) e/ou Clínica"
                  label="Nome da(o) Veterinária(o) e/ou Clínica"
                  name="veterinary"
                  required
                  value={formState.values.veterinary || ""}
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          {formState.showError && (
            <Typography
              className={classes.errorMessage}
              color="error"
              variant="body1"
            >
              {formState.errorMessage}
            </Typography>
          )}
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              data-testid="cancel"
              color="primary"
              variant="contained"
              component={Link}
              to={{
                pathname: "/vaccine-details",
                state: {
                  treatment: vaccine,
                  pet: pet,
                },
              }}
            >
              Cancelar
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              data-testid="submit"
              color="primary"
              variant="contained"
              disabled={!formState.isValid}
              type="submit"
            >
              Salvar
            </Button>
          </Box>
        </Card>
      </form>
    </div>
  );
};

EditVaccine.propTypes = {
  origin: PropTypes.string,
  history: PropTypes.object,
};

export default EditVaccine;
