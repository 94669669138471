import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import * as Medicine from "../../../../controller/data/medicine.js";
import * as Paginate from "../../../../helpers/paginate.js";
import UpdateTreatmentStatus from "../../../UpdateTreatmentStatus";
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Chip,
  Typography,
  Tooltip,
} from "@material-ui/core";

class UpcomingMedicine extends React.Component {
  state = {
    medicine: [],
    addMedicineButtonDisabled: true,
    isLoaded: false,
  };

  componentDidMount() {
    Medicine.getUpcomingMedicine().then((response) => {
      if (response) {
        response.json().then((data) => {
          this.setState({
            medicine: data.treatments,
            addMedicineButtonDisabled: data.addTreatmentDisabled,
            isLoaded: true,
          });
        });
      }
    });
  }

  render() {
    return (
      <ListMedicine
        medicine={this.state.medicine}
        addMedicineButtonDisabled={this.state.addMedicineButtonDisabled}
        isLoaded={this.state.isLoaded}
      />
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  alertMessage: {
    padding: 20,
    color: theme.palette.alert,
    textAlign: "center",
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  statusRoot: {
    marginRight: theme.spacing(1),
  },
  statusLabel: {
    color: "#FFFF",
  },
  actions: {
    justifyContent: "flex-end",
  },
  cellWidth: {
    width: 150,
  },
}));

const statusColors = {
  valid: "#17994d",
  expiring: "#ff9100",
  expired: "#ff3d00",
};

const statusLabels = {
  valid: "Em dia",
  expiring: "Perto de expirar",
  expired: "Expirada",
};

const ListMedicineCardContent = (props) => {
  const { medicine, isLoaded } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [limit] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  if ((medicine === undefined || medicine.length === 0) && isLoaded) {
    return (
      <CardContent className={classes.content}>
        <Typography className={classes.alertMessage} variant="h5">
          {t('Nenhum medicamento agendado para os próximos dois meses.')}
        </Typography>
      </CardContent>
    );
  } else {
    return (
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Medicamento</TableCell>
                  <TableCell width="20%">Pet</TableCell>
                  <TableCell width="10%">Próxima Dose</TableCell>
                  <TableCell width="17%">Situação</TableCell>
                  <TableCell width="10%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Paginate.paginateData(medicine, page, limit).map(
                  (medicine) => (
                    <TableRow
                      data-testid={"medicine-" + medicine.treatment.id}
                      key={medicine.treatment.id}
                    >
                      <TableCell width="17%">
                        {medicine.treatment.name}
                      </TableCell>
                      <TableCell width="17%">{medicine.pet.name}</TableCell>
                      <TableCell width="17%">
                        {medicine.treatment.dates.nextDate}
                      </TableCell>
                      <TableCell width="17%">
                        <Chip
                          classes={{
                            root: classes.statusRoot,
                            label: classes.statusLabel,
                          }}
                          style={{
                            backgroundColor:
                              statusColors[medicine.treatment.state],
                          }}
                          label={statusLabels[medicine.treatment.state]}
                          size="medium"
                        />
                      </TableCell>
                      <TableCell align="right">
                        <UpdateTreatmentStatus
                          pet={medicine.pet}
                          treatment={medicine.treatment}
                          treatmentType="do Medicamento"
                          component="chip"
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={medicine.length}
          onChangePage={handlePageChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10]}
          labelDisplayedRows={(from = page) =>
            `${from.from}-${from.to === -1 ? from.count : from.to} de ${from.count
            }`
          }
        />
      </CardContent>
    );
  }
};

const ListMedicine = (props) => {
  const {
    className,
    medicine,
    addMedicineButtonDisabled,
    history,
    isLoaded,
    ...rest
  } = props;

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        /* action={
          <Tooltip title="Adicionar nova medicação">
            <IconButton
              color="primary"
              size="medium"
              component={Link}
              to={{
                pathname: "/add-medicine",
                origin: "dashboard",
              }}
              disabled={addMedicineButtonDisabled}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        } */
        title={t('Próximas medicações de uso continuo')}
        subheader={t('Medicações que irão expirar nos próximos 2 meses')}
      />
      <Divider />
      <ListMedicineCardContent medicine={medicine} isLoaded={isLoaded} />
    </Card>
  );
};

ListMedicine.propTypes = {
  className: PropTypes.string,
};

UpcomingMedicine.propTypes = {
  className: PropTypes.string,
};

export default UpcomingMedicine;
