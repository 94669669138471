import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/core/styles";
import * as Account from "../../controller/data/account.js";
import { Button, Typography } from "@material-ui/core";
import { LoadingDisco, PasswordInput } from "components";

const schema = {
  password: {
    presence: { allowEmpty: false, message: "Campo obrigatório" },
    length: {
      maximum: 128,
    },
  },
  passwordConfirmation: {
    equality: { attribute: "password", message: "Senhas diferentes" },
    presence: { allowEmpty: false, message: "Campo obrigatório" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    position: "static",
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  contentSuccess: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    textAlign: "center",
  },
  form: {
    width: "100%",
    alignItems: "center",
    paddingLeft: "25%",
    paddingRight: "25%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
  },
}));

const SignUp = () => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const email = new URLSearchParams(search).get("email");

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    showError: false,
    errorMessage: "Algo deu errado, por favor confirme os dados.",
    loading: false,
    loaded: false,
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    const value = event.target.value;
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    setFormState((formState) => ({
      ...formState,
      loading: true,
    }));
    Account.resetPassword(token, email, formState.values.password).then(
      (response) => {
        setFormState((formState) => ({
          ...formState,
          loading: false,
          loaded: true,
        }));
        if (!response.ok) {
          setFormState((formState) => ({
            ...formState,
            showError: true,
          }));
          if (response) {
            response.text().then((msg) => {
              setFormState((formState) => ({
                ...formState,
                errorMessage: msg,
              }));
            });
          }
        }
      }
    );
  };

  const hasError = (field) => {
    return formState.errors[field] && formState.touched[field];
  };

  return (
    <div className={classes.root}>
      {formState.loading && <LoadingDisco />}
      <div className={classes.content}>
        {(!formState.loaded || formState.showError) && (
          <div className={classes.contentBody}>
            <form className={classes.form} onSubmit={handleResetPassword}>
              <Typography className={classes.title} variant="h2">
                Redefinir senha
              </Typography>
              <PasswordInput
                inputProps={{ "data-testid": "password" }}
                label="Senha"
                name="password"
                value={formState.values.password}
                handleChange={handleChange}
                labelWidth={37}
              />
              <PasswordInput
                label="Confirme a senha"
                name="passwordConfirmation"
                value={formState.values.passwordConfirmation}
                handleChange={handleChange}
                labelWidth={108}
                error={hasError("passwordConfirmation")}
                helperText="As senhas estão diferentes"
                inputProps={{ "data-testid": "passwordConfirmation" }}
              />
              {formState.showError && (
                <Typography
                  className={classes.errorMessage}
                  color="error"
                  variant="body1"
                >
                  {formState.errorMessage}
                </Typography>
              )}
              <Button
                data-testid="resetButton"
                className={classes.signUpButton}
                color="primary"
                disabled={!formState.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Redefinir
              </Button>
            </form>
          </div>
        )}
        {formState.loaded && !formState.showError && (
          <div className={classes.contentSuccess}>
            <div className={classes.form}>
              <Typography className={classes.title} variant="h2">
                Senha redefinida com sucesso!
              </Typography>

              <Button
                className={classes.signUpButton}
                color="primary"
                size="medium"
                href="/sign-in"
                variant="outlined"
              >
                Voltar para o login
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SignUp);
