import React, { useState, useEffect } from "react";
import validate from "validate.js";
import PropTypes from "prop-types";
import * as Pet from "../../../controller/data/pet.js";
import { Birthdate } from "../../../helpers/date.js";
import { dateFormat } from "helpers";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  Breadcrumbs,
} from "@material-ui/core";
import format from "date-fns/format";
import DateFnsUtils from "@date-io/date-fns";
import ptBRLocale from "date-fns/locale/pt-BR";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  species: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  sex: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  birthdate: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  element: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  errorMessage: { textAlign: "right", paddingRight: theme.spacing(2) },
}));

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "dd MM yyyy", { locale: this.locale });
  }
}

class EditPet extends React.Component {
  render() {
    if (
      this.props.location.state === undefined ||
      this.props.location.state.pet === undefined
    ) {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <EditPetForm
          history={this.props.history}
          pet={this.props.location.state.pet}
        />
      );
    }
  }
}

const EditPetForm = (props) => {
  const { history, pet } = props;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      name: pet.name,
      sex: pet.sex,
      species: pet.species,
      birthdate: Birthdate(pet.birthdate),
      birthdateValue: pet.birthdate,
    },
    errors: {},
    showError: false,
    errorMessage: "Algo deu errado, por favor confirme os dados.",
  });

  const [changedState, setChangedState] = useState({
    name: false,
    sex: true,
    species: false,
    birthdate: true,
  });

  useEffect(() => {
    validate.extend(validate.validators.datetime, dateFormat);
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));

    setChangedState((changedState) => ({
      ...changedState,
      [event.target.name]: true,
    }));
  };

  const handleBirthdateChange = (date, value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        birthdate: date,
        birthdateValue: value,
      },
    }));
    setChangedState((changedState) => ({
      ...changedState,
      birthdate: true,
    }));
  };

  const handleEditPet = (event) => {
    event.preventDefault();
    Pet.editPet(
      pet.id,
      formState.values.name,
      formState.values.species,
      formState.values.sex,
      formState.values.birthdateValue
    ).then((response) => {
      if (response.ok) {
        pet.name = formState.values.name;
        pet.species = formState.values.species;
        pet.sex = formState.values.sex;
        pet.birthdate = formState.values.birthdateValue;
        history.push({
          pathname: "/pet-profile",
          state: { pet: pet },
        });
      } else {
        setFormState((formState) => ({
          ...formState,
          showError: true,
        }));
        if (response) {
          response.text().then((msg) => {
            setFormState((formState) => ({
              ...formState,
              errorMessage: msg,
            }));
          });
        }
      }
    });
  };

  const hasError = (field) => {
    const error = formState.errors[field] ? true : false;
    return error && changedState[field];
  };

  const handleCancel = (event) => {
    event.preventDefault();
    history.goBack();
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <div style={{ display: "flex" }} className={classes.element}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                color="primary"
                variant="h6"
                component={Link}
                to={{
                  pathname: "/dashboard",
                }}
              >
                Dashboard
              </Typography>

              <Typography
                component={Link}
                variant="h6"
                color="primary"
                onClick={handleCancel}
              >
                {pet.name}
              </Typography>

              <Typography variant="h6" color="textPrimary">
                Editar
              </Typography>
            </Breadcrumbs>
          </div>
        </Grid>
      </Grid>
      <form
        onSubmit={handleEditPet}
        className={classes.element}
        autoComplete="off"
        noValidate
      >
        <Card>
          <CardHeader title="Editar Pet" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  inputProps={{ "data-testid": "name" }}
                  fullWidth
                  helperText={
                    hasError("name")
                      ? "Por favor, escreva o nome do seu pet aqui."
                      : ""
                  }
                  error={hasError("name")}
                  label="Nome do Pet"
                  placeholder="Nome do Pet"
                  name="name"
                  value={formState.values.name || ""}
                  variant="outlined"
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  inputProps={{ "data-testid": "sex" }}
                  fullWidth
                  helperText={
                    hasError("sex")
                      ? "Por favor, selecione o sexo do seu pet."
                      : ""
                  }
                  error={hasError("sex")}
                  onChange={handleChange}
                  name="sex"
                  label="Sexo"
                  placeholder="Sexo"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  value={formState.values.sex || ""}
                  required
                >
                  <option key="female" value="female">
                    Fêmea
                  </option>

                  <option key="male" value="male">
                    Macho
                  </option>
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  inputProps={{ "data-testid": "species" }}
                  fullWidth
                  helperText={
                    hasError("species")
                      ? "Por favor, escreva a espécie do seu pet aqui."
                      : ""
                  }
                  error={hasError("species")}
                  label="Espécie"
                  placeholder="Espécie"
                  name="species"
                  value={formState.values.species || ""}
                  variant="outlined"
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <MuiPickersUtilsProvider
                  utils={LocalizedUtils}
                  locale={ptBRLocale}
                >
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={formState.values.birthdate}
                    label="Data de Nascimento"
                    placeholder="Data de Nascimento"
                    helperText={
                      hasError("birthdate")
                        ? "Por favor, escolha a data de nascimento do seu pet"
                        : ""
                    }
                    error={hasError("birthdate")}
                    onChange={handleBirthdateChange}
                    name="birthdate"
                    required
                    openTo="year"
                    views={["year", "month", "date"]}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          {formState.showError && (
            <Typography
              className={classes.errorMessage}
              color="error"
              variant="body1"
            >
              {formState.errorMessage}
            </Typography>
          )}
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button color="primary" variant="contained" onClick={handleCancel}>
              Cancelar
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              data-testid="submit"
              color="primary"
              disabled={!formState.isValid}
              variant="contained"
              type="submit"
            >
              Salvar
            </Button>
          </Box>
        </Card>
      </form>
    </div>
  );
};

EditPet.propTypes = {
  history: PropTypes.object,
};

export default EditPet;
