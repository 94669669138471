export function createUser(email, password, phoneNumber) {
  return fetch("/api/users", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      password: password,
      phoneNumber: phoneNumber,
    }),
  }).then((response) => {
    return response;
  });
}

export function validateSession() {
  return fetch("/api/validate-session", {
    method: "get",
  }).then((response) => {
    return response.ok;
  });
}

export function signIn(email, password) {
  return fetch("/api/login", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  }).then((response) => {
    return response;
  });
}

export function signOut(email, password) {
  return fetch("/api/logout", {
    method: "post",
  }).then((response) => {
    return response.ok;
  });
}

export function confirmEmail(hash) {
  return fetch("/api/confirm-email?hash=" + hash, {
    method: "put",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

export function requestEmailConfirmation() {
  return fetch("/api/request-confirmation", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
}

export function forgotPassword(email) {
  return fetch("/api/forgot-password?email=" + email, {
    method: "put",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response;
  });
}

export function resetPassword(token, email, password) {
  return fetch("/api/reset-password", {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token: token,
      email: email,
      password: password,
    }),
  }).then((response) => {
    return response;
  });
}

export function changePassword(password, newPassword) {
  return fetch("/api/change-password", {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      password: password,
      newPassword: newPassword,
    }),
  }).then((response) => {
    return response;
  });
}

export function getNotifications() {
  return fetch("/api/notification", {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response;
  });
}

export function getContact() {
  return fetch("/api/users/contact", {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response;
  });
}

export function configureNotification(notification) {
  return fetch("/api/notification", {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      channels: {
        email: notification.email,
      },
      phoneNumber: notification.phoneNumber,
    }),
  }).then((response) => {
    return response;
  });
}

export function deleteAccount() {
  return fetch("/api/users", {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response;
  });
}
