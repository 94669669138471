import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Grid,
  IconButton,
  DialogTitle,
  Dialog,
  Tooltip,
  Button,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    minWidth: 450,
    minHeight: 150,
  },
  item: {
    margin: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(4),
    textAlign: "center",
  },
}));

function DeleteDialog(props) {
  const { onClose, open, onConfirm } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.title} id="customized-dialog-title">
        Tem certeza de que deseja remover este pet da base de dados?
      </DialogTitle>
      <Divider />
      <Grid container justify="center">
        <Button
          className={classes.item}
          color="primary"
          variant="contained"
          type="submit"
          onClick={() => handleClose()}
        >
          Cancelar
        </Button>
        <Button
          className={classes.item}
          color="primary"
          variant="contained"
          type="submit"
          onClick={() => handleConfirmClick()}
        >
          Confirmar
        </Button>
      </Grid>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const DeletePet = (props) => {
  const [open, setOpen] = React.useState(false);
  const { deletePet } = props;

  const handleDelete = () => {
    deletePet();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="Deletar pet">
        <IconButton
          data-testid="delete-pet"
          variant="contained"
          onClick={handleClickOpen}
        >
          <DeleteIcon color="primary" variant="contained" size="small" />
        </IconButton>
      </Tooltip>
      <DeleteDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleDelete}
      />
    </div>
  );
};

class DeletePetComponent extends React.Component {
  render() {
    return <DeletePet deletePet={this.props.deletePet} />;
  }
}

export default DeletePetComponent;
