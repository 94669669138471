import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
} from "@material-ui/core";
import * as Pet from "../../../../controller/data/pet.js";

class MyPets extends React.Component {
  state = {
    pets: [],
    netxPage: "",
    prevPage: "",
    currentPage: "",
    subtitle: "",
    loaded: false,
  };

  componentDidMount() {
    Pet.getPets().then((data) => {
      if (data)
        data.json().then((json) => {
          this.setData(json);
        });
      else this.setState({ loaded: true });
    });
  }

  loadNextPage = (next) => {
    Pet.getPets(next).then((data) => {
      if (data)
        data.json().then((json) => {
          this.setData(json);
        });
      else this.setState({ loaded: true });
    });
  };

  loadPrevPage = (prev) => {
    Pet.getPets(prev).then((data) => {
      if (data)
        data.json().then((json) => {
          this.setData(json);
        });
      else this.setState({ loaded: true });
    });
  };

  setData = (data) => {
    this.setState({
      pets: data.pets,
      netxPage: data.next,
      prevPage: data.prev,
      currentPage: data.page,
      subtitle: data.subtitle,
      loaded: true,
    });
  };

  render() {
    return (
      <ListPets
        pets={this.state.pets}
        currentPage={this.state.currentPage}
        subtitle={this.state.subtitle}
        netxPage={this.state.netxPage}
        prevPage={this.state.prevPage}
        loadNextPage={this.loadNextPage}
        loadPrevPage={this.loadPrevPage}
        loaded={this.state.loaded}
        history={this.props.history}
      />
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    padding: 0,
  },
  image: {
    height: 48,
    width: 48,
  },
  actions: {
    justifyContent: "flex-end",
  },
  alertMessageDiv: {
    padding: 20,
    textAlign: "center",
  },
  alertMessage: {
    color: theme.palette.alert,
  },
  tableRow: {
    cursor: "pointer",
  },
}));

const ListPets = (props) => {
  const {
    className,
    pets,
    currentPage,
    subtitle,
    netxPage,
    prevPage,
    loadNextPage,
    loadPrevPage,
    loaded,
    history,
    ...rest
  } = props;

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const handleRowClick = (rowPet) => {
    history.push({
      pathname: "/pet-profile",
      state: {
        pet: rowPet,
      },
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        data-testid="header"
        action={
          <Tooltip title="Adicionar novo pet">
            <Button color="primary" size="medium" href="/add-pet">
              <AddCircleIcon />
            </Button>
          </Tooltip>
        }
        title={t('Meus Pets')}
      />
      <Divider />
      <CardContent className={classes.content}>
        {pets === undefined ||
          (pets.length === 0 && loaded && (
            <div className={classes.alertMessageDiv}>
              <Typography className={classes.alertMessage} variant="h5">
                {
                  t('Adicione um pet helper')
                }
              </Typography>
            </div>
          ))}
        <Table>
          <TableBody>
            {pets.map((pet, i) => (
              <TableRow
                className={classes.tableRow}
                hover
                data-testid={"pet-" + pet.id}
                key={pet.id}
                onClick={() => handleRowClick(pet)}
              >
                <TableCell>{pet.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      {pets.length != 0 && loaded && (
        <CardActions className={classes.actions}>
          <Typography variant="caption">{subtitle} </Typography>
          <IconButton
            onClick={() => loadPrevPage(prevPage)}
            disabled={!prevPage}
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            onClick={() => loadNextPage(netxPage)}
            disabled={!netxPage}
          >
            <ChevronRightIcon />
          </IconButton>
        </CardActions>
      )}
    </Card>
  );
};

ListPets.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

MyPets.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

export default MyPets;
