import React, { useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import * as Account from "../../controller/data/account.js";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 50,
    textAlign: "center",
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
  dashboardButton: {
    marginTop: 50,
  },
}));

const ConfirmEmail = (props) => {
  const { history } = props;
  const classes = useStyles();

  const [confirmationState, setConfirmationState] = useState({
    loaded: false,
    confirmed: false,
    requested: false,
  });

  const search = useLocation().search;
  const hash = new URLSearchParams(search).get("hash");

  if (!confirmationState.requested) {
    setConfirmationState((confirmationState) => ({
      ...confirmationState,
      requested: true,
    }));

    Account.confirmEmail(hash).then((response) => {
      setConfirmationState((confirmationState) => ({
        ...confirmationState,
        loaded: true,
      }));

      if (response.ok) {
        localStorage.removeItem("showEmailSnackbar");
        setConfirmationState((confirmationState) => ({
          ...confirmationState,
          confirmed: true,
        }));
        window.setTimeout(() => {
          history.push("/dashboard");
        }, 3000);
      } else {
        setConfirmationState((confirmationState) => ({
          ...confirmationState,
          confirmed: false,
        }));
      }
    });
  }

  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="flex-start">
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            {!confirmationState.loaded && !confirmationState.confirmed && (
              <Typography role="loading" variant="h2">
                Aguarde...estamos confirmando seu email
              </Typography>
            )}
            {confirmationState.loaded && !confirmationState.confirmed && (
              <div>
                <Typography role="error" variant="h2">
                  Ops...Algo deu errado! Por favor, entre em contato com
                  suporte@vacinapet.com.
                </Typography>
                <br />
                <br />
              </div>
            )}
            {confirmationState.loaded && confirmationState.confirmed && (
              <div>
                <Typography role="success" variant="h2">
                  Email confirmado com sucesso!
                </Typography>
                <br />
                <Typography role="successSubtitle" variant="subtitle2">
                  Aguarde, você está sendo redirecionado para o dashboard...
                </Typography>
                <br />
                <br />
              </div>
            )}
            {confirmationState.loaded && (
              <Button
                className={classes.dashboardButton}
                color="primary"
                size="medium"
                href="/dashboard"
                variant="outlined"
              >
                Ir para o dashboard
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ConfirmEmail.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ConfirmEmail);
