import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  DialogTitle,
  Dialog,
  IconButton,
  Divider,
  Button,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    minWidth: 450,
    minHeight: 150,
  },
  item: {
    margin: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(4),
    textAlign: "center",
  },
}));

function DeleteDialog(props) {
  const { onClose, open, onConfirm, treatment } = props;
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    onClose();
    onConfirm();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.item} id="dialog-title">
        {`Tem certeza de que deseja deletar ${
          treatment.type === "vaccine" ? "esta vacina" : "este medicamento"
        } ?`}
      </DialogTitle>
      <Divider />
      <Grid container spacing={12} justify="center">
        <Button
          className={classes.item}
          color="primary"
          variant="contained"
          type="submit"
          onClick={() => handleClose()}
        >
          Cancelar
        </Button>
        <Button
          className={classes.item}
          color="primary"
          variant="contained"
          type="submit"
          onClick={() => handleConfirmClick()}
        >
          Confirmar
        </Button>
      </Grid>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const DeleteTreatment = (props) => {
  const [open, setOpen] = React.useState(false);
  const { treatment, petId, deleteTreatment } = props;

  const handleDelete = () => {
    deleteTreatment(petId, treatment.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        variant="contained"
        data-testid={`delete-button-${treatment.id}`}
        onClick={handleClickOpen}
      >
        <DeleteIcon color="primary" variant="contained" size="small" />
      </IconButton>
      <DeleteDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleDelete}
        treatment={treatment}
      />
    </div>
  );
};

class DeleteTreatmentComponent extends React.Component {
  render() {
    return (
      <DeleteTreatment
        treatment={this.props.treatment}
        petId={this.props.petId}
        deleteTreatment={this.props.deleteTreatment}
      />
    );
  }
}

export default DeleteTreatmentComponent;
