import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { SignIn } from "views";
import * as Account from "../../controller/data/account.js";

class SignInRouteWithLayout extends React.Component {
  state = {
    authenticated: false,
    loaded: false,
  };

  componentDidMount() {
    Account.validateSession().then((ok) => {
      this.setState({
        authenticated: ok,
        loaded: true,
      });
    });
  }

  render() {
    if (this.state.authenticated && this.state.loaded) {
      return <Redirect to="/dashboard" />;
    } else if (this.state.loaded) {
      return <SignInRoute {...this.props} />;
    }
    return null;
  }
}

const SignInRoute = (props) => {
  const { layout: Layout, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <SignIn {...matchProps} />
        </Layout>
      )}
    />
  );
};

SignInRouteWithLayout.propTypes = {
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default SignInRouteWithLayout;
