export const Today = {
  date: new Date(),
  string() {
    const t = Today.date;
    var dd = t.getDate();
    if (dd < 10) {
      dd = "0" + dd;
    }
    var mm = t.getMonth() + 1;
    if (mm < 10) {
      mm = "0" + mm;
    }
    var yyyy = t.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  },
};

export const Birthdate = (dateString) => {
  var dateParts = dateString.split("/");
  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
};
