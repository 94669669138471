import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Snackbar,
  Link,
  Breadcrumbs,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import * as Account from "../../controller/data/account.js";
import { Link as LinkDom } from "react-router-dom";

import {
  Notifications,
  Password,
  DeleteAccount,
  PhoneNumber,
} from "./components";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: theme.spacing(4),
  },
  element: {
    paddingBottom: theme.spacing(2),
  },
}));

const Settings = ({ history }) => {
  const classes = useStyles();

  const [showMsg, setShowMsg] = React.useState(false);

  const handleMsgClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowMsg(false);
  };

  const handleDeleteAccount = () => {
    Account.deleteAccount().then((response) => {
      if (response.ok) {
        history.push("/sign-out");
      } else {
        setShowMsg(true);
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <div style={{ display: "flex" }} className={classes.element}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                color="primary"
                variant="h6"
                component={LinkDom}
                to={{
                  pathname: "/dashboard",
                }}
              >
                Dashboard
              </Typography>
              <Typography variant="h6" color="textPrimary">
                Configurações
              </Typography>
            </Breadcrumbs>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={4} justify="flex-end">
        <Grid item md={6} xs={12}>
          <Notifications />
        </Grid>
        <Grid item md={6} xs={12}>
          <Password />
        </Grid>
        <Grid item>
          <DeleteAccount deleteAccount={handleDeleteAccount} />
        </Grid>
      </Grid>
      <Snackbar open={showMsg} onClose={handleMsgClose}>
        <Alert onClose={handleMsgClose} severity="error">
          Falha ao apagar conta. Entre contato com{" "}
          <Link href="mailto:suporte@vacinapet.com">suporte@vacinapet.com</Link>
          .
        </Alert>
      </Snackbar>
    </div>
  );
};

Settings.propTypes = {
  history: PropTypes.object,
};

export default Settings;
